var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Styles } from '../../common';
import { AreaChart, ResponsiveContainer, XAxis, YAxis, Area, Tooltip } from 'recharts';
import CountBadge from '../../common/CountBadge';
import { numberWithCommas } from 'App/utils';
function CustomMetricOverviewChart(props) {
    var data = props.data;
    var gradientDef = Styles.gradientDef();
    return (React.createElement("div", { className: "relative -mx-4" },
        React.createElement("div", { className: "absolute flex items-start flex-col justify-start inset-0 p-3" },
            React.createElement("div", { className: "mb-2 flex items-center" }),
            React.createElement("div", { className: "flex items-center" },
                React.createElement(CountBadge
                // title={subtext}
                , { 
                    // title={subtext}
                    count: countView(Math.round(data.value), data.unit), change: data.progress || 0, unit: data.unit }))),
        React.createElement(ResponsiveContainer, { height: 100, width: "100%" },
            React.createElement(AreaChart, { data: data.chart, margin: {
                    top: 50, right: 0, left: 0, bottom: 5,
                } },
                gradientDef,
                React.createElement(Tooltip, __assign({}, Styles.tooltip)),
                React.createElement(XAxis, __assign({ hide: true }, Styles.xaxis, { interval: 4, dataKey: "time" })),
                React.createElement(YAxis, { hide: true, interval: 0 }),
                React.createElement(Area, { name: '', 
                    // unit={unit && ' ' + unit}
                    type: "monotone", dataKey: "value", stroke: Styles.colors[0], fillOpacity: 1, strokeWidth: 2, strokeOpacity: 0.8, fill: 'url(#colorCount)' })))));
}
export default CustomMetricOverviewChart;
var countView = function (avg, unit) {
    if (unit === 'mb') {
        if (!avg)
            return 0;
        var count = Math.trunc(avg / 1024 / 1024);
        return numberWithCommas(count);
    }
    if (unit === 'min') {
        if (!avg)
            return 0;
        var count = Math.trunc(avg);
        return numberWithCommas(count > 1000 ? count + 'k' : count);
    }
    return avg ? numberWithCommas(avg) : 0;
};
