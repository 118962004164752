var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Button, Modal, Form, Icon } from 'UI';
import { useStore } from 'App/mstore';
import DropdownPlain from 'Shared/DropdownPlain';
function DashboardSelectionModal(props) {
    var show = props.show, metricId = props.metricId, closeHandler = props.closeHandler;
    var dashboardStore = useStore().dashboardStore;
    var dashboardOptions = dashboardStore.dashboards.map(function (i) { return ({
        key: i.id,
        text: i.name,
        value: i.dashboardId,
    }); });
    var _a = __read(React.useState(dashboardOptions[0].value), 2), selectedId = _a[0], setSelectedId = _a[1];
    var onSave = function () {
        var dashboard = dashboardStore.getDashboard(selectedId);
        if (dashboard) {
            dashboardStore.addWidgetToDashboard(dashboard, [metricId]).then(closeHandler);
        }
    };
    return useObserver(function () { return (React.createElement(Modal, { size: "tiny", open: show },
        React.createElement(Modal.Header, { className: "flex items-center justify-between" },
            React.createElement("div", null, 'Add to selected dashboard'),
            React.createElement(Icon, { role: "button", tabIndex: "-1", color: "gray-dark", size: "14", name: "close", onClick: closeHandler })),
        React.createElement(Modal.Content, null,
            React.createElement("div", { className: "py-4" },
                React.createElement(Form, { onSubmit: onSave },
                    React.createElement(Form.Field, null,
                        React.createElement("label", null, 'Dashbaord:'),
                        React.createElement(DropdownPlain, { options: dashboardOptions, value: selectedId, onChange: function (e, _a) {
                                var value = _a.value;
                                return setSelectedId(value);
                            } }))))),
        React.createElement(Modal.Actions, null,
            React.createElement("div", { className: "-mx-2 px-2" },
                React.createElement(Button, { primary: true, onClick: onSave }, "Add"),
                React.createElement(Button, { className: "", marginRight: true, onClick: closeHandler }, 'Cancel'))))); });
}
export default DashboardSelectionModal;
