import { makeAutoObservable, observable, action, runInAction } from "mobx";
import Widget from "./widget";
import { dashboardService } from "App/services";
import { toast } from 'react-toastify';
var Dashboard = /** @class */ (function () {
    function Dashboard() {
        this.dashboardId = undefined;
        this.name = "New Dashboard";
        this.isPublic = true;
        this.widgets = [];
        this.metrics = [];
        this.isValid = false;
        this.isPinned = false;
        this.currentWidget = new Widget();
        this.config = {};
        makeAutoObservable(this, {
            name: observable,
            isPublic: observable,
            widgets: observable,
            isValid: observable,
            metrics: observable,
            toJson: action,
            fromJson: action,
            addWidget: action,
            removeWidget: action,
            updateWidget: action,
            getWidget: action,
            getWidgetIndex: action,
            getWidgetByIndex: action,
            getWidgetCount: action,
            getWidgetIndexByWidgetId: action,
            validate: action,
            sortWidgets: action,
            swapWidgetPosition: action,
            update: action,
            toggleMetrics: action
        });
        this.validate();
    }
    Object.defineProperty(Dashboard, "ID_KEY", {
        get: function () { return "dashboardId"; },
        enumerable: false,
        configurable: true
    });
    Dashboard.prototype.update = function (data) {
        var _this = this;
        runInAction(function () {
            Object.assign(_this, data);
        });
        this.validate();
    };
    Dashboard.prototype.toJson = function () {
        return {
            dashboardId: this.dashboardId,
            name: this.name,
            isPublic: this.isPublic,
            // widgets: this.widgets.map(w => w.toJson())
            // widgets: this.widgets
            metrics: this.metrics
        };
    };
    Dashboard.prototype.fromJson = function (json) {
        var _this = this;
        runInAction(function () {
            _this.dashboardId = json.dashboardId;
            _this.name = json.name;
            _this.isPublic = json.isPublic;
            _this.isPinned = json.isPinned;
            _this.widgets = json.widgets ? json.widgets.map(function (w) { return new Widget().fromJson(w); }).sort(function (a, b) { return a.position - b.position; }) : [];
        });
        return this;
    };
    Dashboard.prototype.validate = function () {
        return this.isValid = this.name.length > 0;
    };
    Dashboard.prototype.addWidget = function (widget) {
        this.widgets.push(widget);
    };
    Dashboard.prototype.removeWidget = function (widgetId) {
        this.widgets = this.widgets.filter(function (w) { return w.widgetId !== widgetId; });
    };
    Dashboard.prototype.updateWidget = function (widget) {
        var index = this.widgets.findIndex(function (w) { return w.widgetId === widget.widgetId; });
        if (index >= 0) {
            this.widgets[index] = widget;
        }
    };
    Dashboard.prototype.getWidget = function (widgetId) {
        return this.widgets.find(function (w) { return w.widgetId === widgetId; });
    };
    Dashboard.prototype.getWidgetIndex = function (widgetId) {
        return this.widgets.findIndex(function (w) { return w.widgetId === widgetId; });
    };
    Dashboard.prototype.getWidgetByIndex = function (index) {
        return this.widgets[index];
    };
    Dashboard.prototype.getWidgetCount = function () {
        return this.widgets.length;
    };
    Dashboard.prototype.getWidgetIndexByWidgetId = function (widgetId) {
        return this.widgets.findIndex(function (w) { return w.widgetId === widgetId; });
    };
    Dashboard.prototype.swapWidgetPosition = function (positionA, positionB) {
        var _this = this;
        var widgetA = this.widgets[positionA];
        var widgetB = this.widgets[positionB];
        this.widgets[positionA] = widgetB;
        this.widgets[positionB] = widgetA;
        widgetA.position = positionB;
        widgetB.position = positionA;
        return new Promise(function (resolve, reject) {
            Promise.all([
                dashboardService.saveWidget(_this.dashboardId, widgetA),
                dashboardService.saveWidget(_this.dashboardId, widgetB)
            ]).then(function () {
                toast.success("Widget position updated");
                resolve();
            }).catch(function () {
                toast.error("Error updating widget position");
                reject();
            });
        });
    };
    Dashboard.prototype.sortWidgets = function () {
        this.widgets = this.widgets.sort(function (a, b) {
            if (a.position > b.position) {
                return 1;
            }
            else if (a.position < b.position) {
                return -1;
            }
            else {
                return 0;
            }
        });
    };
    Dashboard.prototype.exists = function () {
        return this.dashboardId !== undefined;
    };
    Dashboard.prototype.toggleMetrics = function (metricId) {
        if (this.metrics.includes(metricId)) {
            this.metrics = this.metrics.filter(function (m) { return m !== metricId; });
        }
        else {
            this.metrics.push(metricId);
        }
    };
    return Dashboard;
}());
export default Dashboard;
