import React from 'react';
import { useModal } from 'App/components/Modal';
import stl from './ModalOverlay.css';
function ModalOverlay(_a) {
    var children = _a.children;
    var modal = useModal();
    return (React.createElement("div", { className: "fixed w-full h-screen", style: { zIndex: '999' } },
        React.createElement("div", { onClick: function () { return modal.hideModal(); }, className: stl.overlay, style: { background: "rgba(0,0,0,0.5)" } }),
        React.createElement("div", { className: stl.slide }, children)));
}
export default ModalOverlay;
