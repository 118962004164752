var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { NoContent } from 'UI';
import { Styles } from '../../common';
import { BarChart, Bar, CartesianGrid, Tooltip, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
function ErrorsByType(props) {
    var data = props.data, metric = props.metric;
    return (React.createElement(NoContent, { size: "small", show: metric.data.chart.length === 0, style: { height: '240px' } },
        React.createElement(ResponsiveContainer, { height: 240, width: "100%" },
            React.createElement(BarChart, { data: metric.data.chart, margin: Styles.chartMargins },
                React.createElement(CartesianGrid, { strokeDasharray: "3 3", vertical: false, stroke: "#EEEEEE" }),
                React.createElement(XAxis, __assign({}, Styles.xaxis, { dataKey: "time", interval: metric.params.density / 7 })),
                React.createElement(YAxis, __assign({}, Styles.yaxis, { label: __assign(__assign({}, Styles.axisLabelLeft), { value: "Number of Errors" }), allowDecimals: false })),
                React.createElement(Legend, null),
                React.createElement(Tooltip, __assign({}, Styles.tooltip)),
                React.createElement(Bar, { minPointSize: 1, name: "Integrations", dataKey: "integrations", stackId: "a", fill: Styles.colors[0] }),
                React.createElement(Bar, { name: "4xx", dataKey: "4xx", stackId: "a", fill: Styles.colors[1] }),
                React.createElement(Bar, { name: "5xx", dataKey: "5xx", stackId: "a", fill: Styles.colors[2] }),
                React.createElement(Bar, { name: "Javascript", dataKey: "js", stackId: "a", fill: Styles.colors[3] })))));
}
export default ErrorsByType;
