var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { Icon, Loader } from 'UI';
import APIClient from 'App/api_client';
import { debounce } from 'App/utils';
import stl from './FilterAutoComplete.css';
import cn from 'classnames';
var hiddenStyle = {
    whiteSpace: 'pre-wrap',
    opacity: 0, position: 'fixed', left: '-3000px'
};
function FilterAutoComplete(props) {
    var _a = props.showCloseButton, showCloseButton = _a === void 0 ? false : _a, _b = props.placeholder, placeholder = _b === void 0 ? 'Type to search' : _b, _c = props.method, method = _c === void 0 ? 'GET' : _c, _d = props.showOrButton, showOrButton = _d === void 0 ? false : _d, _e = props.onRemoveValue, onRemoveValue = _e === void 0 ? function () { return null; } : _e, _f = props.onAddValue, onAddValue = _f === void 0 ? function () { return null; } : _f, _g = props.endpoint, endpoint = _g === void 0 ? '' : _g, _h = props.params, params = _h === void 0 ? {} : _h, _j = props.headerText, headerText = _j === void 0 ? '' : _j, _k = props.value, value = _k === void 0 ? '' : _k, _l = props.icon, icon = _l === void 0 ? null : _l;
    var _m = __read(useState(false), 2), showModal = _m[0], setShowModal = _m[1];
    var _o = __read(useState(false), 2), loading = _o[0], setLoading = _o[1];
    var _p = __read(useState([]), 2), options = _p[0], setOptions = _p[1];
    var _q = __read(useState(value), 2), query = _q[0], setQuery = _q[1];
    var requestValues = function (q) {
        setLoading(true);
        return new APIClient()[method === null || method === void 0 ? void 0 : method.toLocaleLowerCase()](endpoint, __assign(__assign({}, params), { q: q }))
            .then(function (response) {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response.statusText);
        })
            .then(function (_a) {
            var data = _a.data;
            setOptions(data);
        })
            .finally(function () { return setLoading(false); });
    };
    var debouncedRequestValues = React.useCallback(debounce(requestValues, 1000), [params]);
    var onInputChange = function (_a) {
        var value = _a.target.value;
        setQuery(value);
        if (!showModal) {
            setShowModal(true);
        }
        if (value === '' || value === ' ') {
            return;
        }
        debouncedRequestValues(value);
    };
    useEffect(function () {
        setQuery(value);
    }, [value]);
    var onBlur = function (e) {
        setTimeout(function () { setShowModal(false); }, 200);
        if (query !== value) {
            props.onSelect(e, { value: query });
        }
    };
    var onItemClick = function (e, item) {
        e.stopPropagation();
        e.preventDefault();
        if (query !== item.value) {
            setQuery(item.value);
        }
        props.onSelect(e, item);
    };
    return (React.createElement("div", { className: "relative flex items-center" },
        React.createElement("div", { className: stl.wrapper },
            React.createElement("input", { name: "query", onChange: onInputChange, onBlur: onBlur, value: query, autoFocus: true, type: "text", placeholder: placeholder }),
            React.createElement("div", { className: stl.right },
                showCloseButton && React.createElement("div", { onClick: onRemoveValue },
                    React.createElement(Icon, { name: "close", size: "12" })),
                showOrButton && React.createElement("div", { onClick: onAddValue, className: "color-teal" },
                    React.createElement("span", { className: "px-1" }, "or")))),
        !showOrButton && React.createElement("div", { className: "ml-3" }, "or"),
        showModal && (React.createElement("div", { className: stl.menu },
            React.createElement(Loader, { loading: loading, size: "small" }, options.length === 0 ? (React.createElement("div", { className: "p-4 w-full" }, "No results found!")) : (React.createElement("div", null, options.map(function (item, i) { return (React.createElement("div", { key: item.value + '_' + i, className: cn(stl.filterItem), id: "filter-item", onClick: function (e) { return onItemClick(e, item); } },
                icon && React.createElement(Icon, { name: icon, size: "16", marginRight: "8" }),
                React.createElement("span", { className: stl.label }, item.value))); }))))))));
}
export default FilterAutoComplete;
