var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect, useState } from 'react';
import { NoContent, Dropdown, Icon, Loader, Pagination } from 'UI';
import cn from 'classnames';
import { useStore } from 'App/mstore';
import SessionItem from 'Shared/SessionItem';
import { observer, useObserver } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import { debounce } from 'App/utils';
function WidgetSessions(props) {
    var _a = props.className, className = _a === void 0 ? '' : _a;
    var _b = __read(useState([]), 2), data = _b[0], setData = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState([
        { text: 'All', value: 'all' },
    ]), 2), seriesOptions = _d[0], setSeriesOptions = _d[1];
    var _e = __read(useState('all'), 2), activeSeries = _e[0], setActiveSeries = _e[1];
    var writeOption = function (e, _a) {
        var name = _a.name, value = _a.value;
        return setActiveSeries(value);
    };
    useEffect(function () {
        if (!data)
            return;
        var seriesOptions = data.map(function (item) { return ({
            text: item.seriesName,
            value: item.seriesId,
        }); });
        setSeriesOptions(__spread([
            { text: 'All', value: 'all' }
        ], seriesOptions));
    }, [data]);
    var fetchSessions = function (metricId, filter) {
        setLoading(true);
        widget.fetchSessions(metricId, filter).then(function (res) {
            setData(res);
        }).finally(function () {
            setLoading(false);
        });
    };
    var filteredSessions = getListSessionsBySeries(data, activeSeries);
    var _f = useStore(), dashboardStore = _f.dashboardStore, metricStore = _f.metricStore;
    var filter = useObserver(function () { return dashboardStore.drillDownFilter; });
    var widget = useObserver(function () { return metricStore.instance; });
    var startTime = DateTime.fromMillis(filter.startTimestamp).toFormat('LLL dd, yyyy HH:mm a');
    var endTime = DateTime.fromMillis(filter.endTimestamp).toFormat('LLL dd, yyyy HH:mm a');
    var debounceRequest = React.useCallback(debounce(fetchSessions, 1000), []);
    var depsString = JSON.stringify(widget.series);
    useEffect(function () {
        debounceRequest(widget.metricId, __assign(__assign({}, filter), { series: widget.toJsonDrilldown(), page: metricStore.sessionsPage, limit: metricStore.sessionsPageSize }));
    }, [filter.startTimestamp, filter.endTimestamp, filter.filters, depsString, metricStore.sessionsPage]);
    return useObserver(function () { return (React.createElement("div", { className: cn(className) },
        React.createElement("div", { className: "flex items-center justify-between" },
            React.createElement("div", { className: "flex items-baseline" },
                React.createElement("h2", { className: "text-2xl" }, "Sessions"),
                React.createElement("div", { className: "ml-2 color-gray-medium" },
                    "between ",
                    React.createElement("span", { className: "font-medium color-gray-darkest" }, startTime),
                    " and ",
                    React.createElement("span", { className: "font-medium color-gray-darkest" }, endTime),
                    " ")),
            widget.metricType !== 'table' && (React.createElement("div", { className: "flex items-center ml-6" },
                React.createElement("span", { className: "mr-2 color-gray-medium" }, "Series"),
                React.createElement(Dropdown
                // className={stl.dropdown}
                , { 
                    // className={stl.dropdown}
                    className: "font-medium flex items-center hover:bg-gray-light rounded px-2 py-1", direction: "left", options: seriesOptions, name: "change", value: activeSeries, onChange: writeOption, id: "change-dropdown", 
                    // icon={null}
                    icon: React.createElement(Icon, { name: "chevron-down", color: "gray-dark", size: "14", className: "ml-2" }) })))),
        React.createElement("div", { className: "mt-3" },
            React.createElement(Loader, { loading: loading },
                React.createElement(NoContent, { title: "No recordings found", show: filteredSessions.sessions.length === 0, animatedIcon: "no-results" },
                    filteredSessions.sessions.map(function (session) { return (React.createElement(SessionItem, { key: session.sessionId, session: session })); }),
                    React.createElement("div", { className: "w-full flex items-center justify-center py-6" },
                        React.createElement(Pagination, { page: metricStore.sessionsPage, totalPages: Math.ceil(filteredSessions.total / metricStore.sessionsPageSize), onPageChange: function (page) { return metricStore.updateKey('sessionsPage', page); }, limit: metricStore.sessionsPageSize, debounceRequest: 500 }))))))); });
}
var getListSessionsBySeries = function (data, seriesId) {
    var arr = { sessions: [], total: 0 };
    data.forEach(function (element) {
        var _a, _b;
        if (seriesId === 'all') {
            var sessionIds_1 = arr.sessions.map(function (i) { return i.sessionId; });
            (_a = arr.sessions).push.apply(_a, __spread(element.sessions.filter(function (i) { return !sessionIds_1.includes(i.sessionId); })));
            arr.total = element.total;
        }
        else {
            if (element.seriesId === seriesId) {
                (_b = arr.sessions).push.apply(_b, __spread(element.sessions));
                arr.total = element.total;
            }
        }
    });
    return arr;
};
export default observer(WidgetSessions);
