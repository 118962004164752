var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeAutoObservable, runInAction, observable, action } from "mobx";
import Dashboard from "./types/dashboard";
import Widget from "./types/widget";
import { dashboardService, metricService } from "App/services";
import { toast } from 'react-toastify';
import Period, { LAST_7_DAYS } from 'Types/app/period';
import { getChartFormatter } from 'Types/dashboard/helper';
import Filter from "./types/filter";
var DashboardStore = /** @class */ (function () {
    function DashboardStore() {
        var _this = this;
        this.siteId = null;
        // Dashbaord / Widgets
        this.dashboards = [];
        this.selectedDashboard = null;
        this.dashboardInstance = new Dashboard();
        this.selectedWidgets = [];
        this.currentWidget = new Widget();
        this.widgetCategories = [];
        this.widgets = [];
        this.period = Period({ rangeName: LAST_7_DAYS });
        this.drillDownFilter = new Filter();
        this.startTimestamp = 0;
        this.endTimestamp = 0;
        // Metrics
        this.metricsPage = 1;
        this.metricsPageSize = 10;
        this.metricsSearch = '';
        // Loading states
        this.isLoading = true;
        this.isSaving = false;
        this.isDeleting = false;
        this.loadingTemplates = false;
        this.fetchingDashboard = false;
        this.sessionsLoading = false;
        this.showAlertModal = false;
        this.removeSelectedWidgetByCategory = function (category) {
            var categoryWidgetIds = category.widgets.map(function (w) { return w.metricId; });
            _this.selectedWidgets = _this.selectedWidgets.filter(function (widget) { return !categoryWidgetIds.includes(widget.metricId); });
        };
        this.toggleWidgetSelection = function (widget) {
            var selectedWidgetIds = _this.selectedWidgets.map(function (widget) { return widget.metricId; });
            if (selectedWidgetIds.includes(widget.metricId)) {
                _this.selectedWidgets = _this.selectedWidgets.filter(function (w) { return w.metricId !== widget.metricId; });
            }
            else {
                _this.selectedWidgets.push(widget);
            }
        };
        this.setSiteId = function (siteId) {
            _this.siteId = siteId;
        };
        this.selectDefaultDashboard = function () {
            return new Promise(function (resolve, reject) {
                if (_this.dashboards.length > 0) {
                    var pinnedDashboard = _this.dashboards.find(function (d) { return d.isPinned; });
                    if (pinnedDashboard) {
                        _this.selectedDashboard = pinnedDashboard;
                    }
                    else {
                        _this.selectedDashboard = _this.dashboards[0];
                    }
                }
                if (_this.selectedDashboard) {
                    resolve(_this.selectedDashboard);
                }
                // reject(new Error("No dashboards found"))
            });
        };
        makeAutoObservable(this, {
            drillDownFilter: observable.ref,
            widgetCategories: observable.ref,
            resetCurrentWidget: action,
            addDashboard: action,
            removeDashboard: action,
            updateDashboard: action,
            getDashboard: action,
            getDashboardByIndex: action,
            getDashboardCount: action,
            selectDashboardById: action,
            selectDefaultDashboard: action,
            toJson: action,
            fromJson: action,
            setSiteId: action,
            editWidget: action,
            updateKey: action,
            selectWidgetsByCategory: action,
            toggleAllSelectedWidgets: action,
            removeSelectedWidgetByCategory: action,
            toggleWidgetSelection: action,
            fetchTemplates: action,
            updatePinned: action,
            setPeriod: action,
            fetchMetricChartData: action
        });
        var drillDownPeriod = Period({ rangeName: LAST_7_DAYS }).toTimestamps();
        this.drillDownFilter.updateKey('startTimestamp', drillDownPeriod.startTimestamp);
        this.drillDownFilter.updateKey('endTimestamp', drillDownPeriod.endTimestamp);
    }
    DashboardStore.prototype.toggleAllSelectedWidgets = function (isSelected) {
        if (isSelected) {
            var allWidgets = this.widgetCategories.reduce(function (acc, cat) {
                return acc.concat(cat.widgets);
            }, []);
            this.selectedWidgets = allWidgets;
        }
        else {
            this.selectedWidgets = [];
        }
    };
    DashboardStore.prototype.selectWidgetsByCategory = function (category) {
        var _a;
        var selectedWidgetIds = this.selectedWidgets.map(function (widget) { return widget.metricId; });
        var widgets = (_a = this.widgetCategories.find(function (cat) { return cat.name === category; })) === null || _a === void 0 ? void 0 : _a.widgets.filter(function (widget) { return !selectedWidgetIds.includes(widget.metricId); });
        this.selectedWidgets = this.selectedWidgets.concat(widgets) || [];
    };
    DashboardStore.prototype.findByIds = function (ids) {
        return this.dashboards.filter(function (d) { return ids.includes(d.dashboardId); });
    };
    DashboardStore.prototype.initDashboard = function (dashboard) {
        this.dashboardInstance = dashboard ? new Dashboard().fromJson(dashboard) : new Dashboard();
        this.selectedWidgets = [];
    };
    DashboardStore.prototype.updateKey = function (key, value) {
        this[key] = value;
    };
    DashboardStore.prototype.resetCurrentWidget = function () {
        this.currentWidget = new Widget();
    };
    DashboardStore.prototype.editWidget = function (widget) {
        this.currentWidget.update(widget);
    };
    DashboardStore.prototype.fetchList = function () {
        var _this = this;
        this.isLoading = true;
        return dashboardService.getDashboards()
            .then(function (list) {
            runInAction(function () {
                _this.dashboards = list.map(function (d) { return new Dashboard().fromJson(d); });
            });
        }).finally(function () {
            runInAction(function () {
                _this.isLoading = false;
            });
        });
    };
    DashboardStore.prototype.fetch = function (dashboardId) {
        var _this = this;
        this.fetchingDashboard = true;
        return dashboardService.getDashboard(dashboardId).then(function (response) {
            var _a;
            // const widgets =  new Dashboard().fromJson(response).widgets
            (_a = _this.selectedDashboard) === null || _a === void 0 ? void 0 : _a.update({ 'widgets': new Dashboard().fromJson(response).widgets });
        }).finally(function () {
            _this.fetchingDashboard = false;
        });
    };
    DashboardStore.prototype.save = function (dashboard) {
        var _this = this;
        this.isSaving = true;
        var isCreating = !dashboard.dashboardId;
        dashboard.metrics = this.selectedWidgets.map(function (w) { return w.metricId; });
        return new Promise(function (resolve, reject) {
            dashboardService.saveDashboard(dashboard).then(function (_dashboard) {
                runInAction(function () {
                    var newDashboard = new Dashboard().fromJson(_dashboard);
                    if (isCreating) {
                        toast.success('Dashboard created successfully');
                        _this.addDashboard(newDashboard);
                    }
                    else {
                        toast.success('Dashboard updated successfully');
                        _this.updateDashboard(newDashboard);
                    }
                    resolve(newDashboard);
                });
            }).catch(function (error) {
                toast.error('Error saving dashboard');
                reject();
            }).finally(function () {
                runInAction(function () {
                    _this.isSaving = false;
                });
            });
        });
    };
    DashboardStore.prototype.saveMetric = function (metric, dashboardId) {
        var _this = this;
        var isCreating = !metric.widgetId;
        return dashboardService.saveMetric(metric, dashboardId).then(function (metric) {
            runInAction(function () {
                var _a, _b;
                if (isCreating) {
                    (_a = _this.selectedDashboard) === null || _a === void 0 ? void 0 : _a.widgets.push(metric);
                }
                else {
                    (_b = _this.selectedDashboard) === null || _b === void 0 ? void 0 : _b.widgets.map(function (w) {
                        if (w.widgetId === metric.widgetId) {
                            w.update(metric);
                        }
                    });
                }
            });
        });
    };
    DashboardStore.prototype.saveDashboardWidget = function (dashboard, widget) {
        widget.validate();
        if (widget.isValid) {
            this.isLoading = true;
        }
    };
    DashboardStore.prototype.deleteDashboard = function (dashboard) {
        var _this = this;
        this.isDeleting = true;
        return dashboardService.deleteDashboard(dashboard.dashboardId).then(function () {
            toast.success('Dashboard deleted successfully');
            runInAction(function () {
                _this.removeDashboard(dashboard);
            });
        })
            .catch(function () {
            toast.error('Dashboard could not be deleted');
        })
            .finally(function () {
            runInAction(function () {
                _this.isDeleting = false;
            });
        });
    };
    DashboardStore.prototype.toJson = function () {
        return {
            dashboards: this.dashboards.map(function (d) { return d.toJson(); })
        };
    };
    DashboardStore.prototype.fromJson = function (json) {
        var _this = this;
        runInAction(function () {
            _this.dashboards = json.dashboards.map(function (d) { return new Dashboard().fromJson(d); });
        });
        return this;
    };
    DashboardStore.prototype.addDashboard = function (dashboard) {
        this.dashboards.push(new Dashboard().fromJson(dashboard));
    };
    DashboardStore.prototype.removeDashboard = function (dashboard) {
        this.dashboards = this.dashboards.filter(function (d) { return d.dashboardId !== dashboard.dashboardId; });
    };
    DashboardStore.prototype.getDashboard = function (dashboardId) {
        return this.dashboards.find(function (d) { return d.dashboardId === dashboardId; }) || null;
    };
    DashboardStore.prototype.getDashboardByIndex = function (index) {
        return this.dashboards[index];
    };
    DashboardStore.prototype.getDashboardCount = function () {
        return this.dashboards.length;
    };
    DashboardStore.prototype.updateDashboard = function (dashboard) {
        var index = this.dashboards.findIndex(function (d) { return d.dashboardId === dashboard.dashboardId; });
        if (index >= 0) {
            this.dashboards[index] = dashboard;
        }
    };
    DashboardStore.prototype.selectDashboardById = function (dashboardId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.selectedDashboard = _this.dashboards.find(function (d) { return d.dashboardId == dashboardId; }) || new Dashboard();
            resolve(_this.selectedDashboard);
        });
        // if (this.selectedDashboard.dashboardId) {
        //     this.fetch(this.selectedDashboard.dashboardId)
        // }
    };
    DashboardStore.prototype.fetchTemplates = function () {
        var _this = this;
        this.loadingTemplates = true;
        return new Promise(function (resolve, reject) {
            metricService.getTemplates().then(function (response) {
                var categories = [];
                response.forEach(function (category) {
                    var widgets = [];
                    category.widgets.filter(function (w) { return w.predefinedKey !== 'speed_locations'; }).forEach(function (widget) {
                        var w = new Widget().fromJson(widget);
                        widgets.push(w);
                    });
                    var c = {};
                    c.widgets = widgets;
                    c.name = category.category;
                    c.description = category.description;
                    categories.push(c);
                });
                _this.widgetCategories = categories;
                resolve(_this.widgetCategories);
            }).catch(function (error) {
                reject(error);
            }).finally(function () {
                _this.loadingTemplates = false;
            });
        });
    };
    DashboardStore.prototype.deleteDashboardWidget = function (dashboardId, widgetId) {
        var _this = this;
        this.isDeleting = true;
        return dashboardService.deleteWidget(dashboardId, widgetId).then(function () {
            toast.success('Widget deleted successfully');
            runInAction(function () {
                var _a;
                (_a = _this.selectedDashboard) === null || _a === void 0 ? void 0 : _a.removeWidget(widgetId);
            });
        }).finally(function () {
            _this.isDeleting = false;
        });
    };
    DashboardStore.prototype.addWidgetToDashboard = function (dashboard, metricIds) {
        var _this = this;
        this.isSaving = true;
        return dashboardService.addWidget(dashboard, metricIds)
            .then(function (response) {
            toast.success('Widget added successfully');
        }).catch(function () {
            toast.error('Widget could not be added');
        }).finally(function () {
            _this.isSaving = false;
        });
    };
    DashboardStore.prototype.updatePinned = function (dashboardId) {
        var _this = this;
        // this.isSaving = true
        return dashboardService.updatePinned(dashboardId).then(function () {
            toast.success('Dashboard pinned successfully');
            _this.dashboards.forEach(function (d) {
                if (d.dashboardId === dashboardId) {
                    d.isPinned = true;
                }
                else {
                    d.isPinned = false;
                }
            });
        }).catch(function () {
            toast.error('Dashboard could not be pinned');
        }).finally(function () {
            // this.isSaving = false
        });
    };
    DashboardStore.prototype.setPeriod = function (period) {
        this.period = Period({ start: period.startDate, end: period.endDate, rangeName: period.rangeValue });
    };
    DashboardStore.prototype.fetchMetricChartData = function (metric, data, isWidget) {
        var _this = this;
        if (isWidget === void 0) { isWidget = false; }
        var period = this.period.toTimestamps();
        return new Promise(function (resolve, reject) {
            // this.isLoading = true
            return metricService.getMetricChartData(metric, __assign(__assign(__assign({}, period), data), { key: metric.predefinedKey }), isWidget)
                .then(function (data) {
                if (metric.metricType === 'predefined' && metric.viewType === 'overview') {
                    var _data = __assign(__assign({}, data), { chart: getChartFormatter(_this.period)(data.chart) });
                    metric.setData(_data);
                    resolve(_data);
                }
                else {
                    var _data = __assign({}, data);
                    if (data.hasOwnProperty('chart')) {
                        _data['chart'] = getChartFormatter(_this.period)(data.chart);
                        _data['namesMap'] = data.chart
                            .map(function (i) { return Object.keys(i); })
                            .flat()
                            .filter(function (i) { return i !== 'time' && i !== 'timestamp'; })
                            .reduce(function (unique, item) {
                            if (!unique.includes(item)) {
                                unique.push(item);
                            }
                            return unique;
                        }, []);
                    }
                    else {
                        _data['chart'] = getChartFormatter(_this.period)(Array.isArray(data) ? data : []);
                        _data['namesMap'] = Array.isArray(data) ? data.map(function (i) { return Object.keys(i); })
                            .flat()
                            .filter(function (i) { return i !== 'time' && i !== 'timestamp'; })
                            .reduce(function (unique, item) {
                            if (!unique.includes(item)) {
                                unique.push(item);
                            }
                            return unique;
                        }, []) : [];
                    }
                    metric.setData(_data);
                    resolve(_data);
                }
            }).catch(function (err) {
                reject(err);
            });
        });
    };
    return DashboardStore;
}());
export default DashboardStore;
