var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { NoContent } from 'UI';
import { Styles } from '../../common';
import { ComposedChart, Bar, CartesianGrid, Line, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
function ResourceLoadedVsResponseEnd(props) {
    var data = props.data, metric = props.metric;
    return (React.createElement(NoContent, { size: "small", show: metric.data.chart.length === 0 },
        React.createElement(ResponsiveContainer, { height: 240, width: "100%" },
            React.createElement(ComposedChart, { data: metric.data.chart, margin: Styles.chartMargins },
                React.createElement(CartesianGrid, { strokeDasharray: "3 3", vertical: false, stroke: "#EEEEEE" }),
                React.createElement(XAxis, __assign({}, Styles.xaxis, { dataKey: "time", 
                    // interval={3}
                    interval: (metric.params.density / 7) })),
                React.createElement(YAxis, __assign({}, Styles.yaxis, { label: __assign(__assign({}, Styles.axisLabelLeft), { value: "Number of Resources" }), yAxisId: "left", tickFormatter: function (val) { return Styles.tickFormatter(val, 'ms'); } })),
                React.createElement(YAxis, __assign({}, Styles.yaxis, { label: __assign(__assign({}, Styles.axisLabelLeft), { value: "Response End (ms)", position: "insideRight", offset: 0 }), yAxisId: "right", orientation: "right", tickFormatter: function (val) { return Styles.tickFormatter(val, 'ms'); } })),
                React.createElement(Tooltip, __assign({}, Styles.tooltip)),
                React.createElement(Legend, null),
                React.createElement(Bar, { minPointSize: 1, yAxisId: "left", name: "XHR", dataKey: "xhr", stackId: "a", fill: Styles.colors[0] }),
                React.createElement(Bar, { yAxisId: "left", name: "Other", dataKey: "total", stackId: "a", fill: Styles.colors[2] }),
                React.createElement(Line, { yAxisId: "right", strokeWidth: 2, name: "Response End", type: "monotone", dataKey: "avgResponseEnd", stroke: Styles.lineColor, dot: false })))));
}
export default ResourceLoadedVsResponseEnd;
