import React from 'react';
import { NoContent } from 'UI';
import { Styles } from '../../common';
import { numberWithCommas } from 'App/utils';
import Bar from 'App/components/Dashboard/Widgets/ErrorsPerDomain/Bar';
function ErrorsPerDomain(props) {
    var data = props.data, metric = props.metric;
    // const firstAvg = 10;
    var firstAvg = metric.data.chart[0] && metric.data.chart[0].errorsCount;
    return (React.createElement(NoContent, { size: "small", show: metric.data.chart.length === 0, style: { height: '240px' } },
        React.createElement("div", { className: "w-full", style: { height: '240px' } }, metric.data.chart.map(function (item, i) {
            return React.createElement(Bar, { key: i, className: "mb-2", avg: numberWithCommas(Math.round(item.errorsCount)), width: Math.round((item.errorsCount * 100) / firstAvg) - 10, domain: item.domain, color: Styles.colors[i] });
        }))));
}
export default ErrorsPerDomain;
