var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useStore } from 'App/mstore';
import WidgetForm from '../WidgetForm';
import WidgetPreview from '../WidgetPreview';
import WidgetSessions from '../WidgetSessions';
import { Icon, BackLink, Loader } from 'UI';
import { useObserver } from 'mobx-react-lite';
import { withSiteId } from 'App/routes';
import WidgetName from '../WidgetName';
function WidgetView(props) {
    var _a = props.match.params, siteId = _a.siteId, dashboardId = _a.dashboardId, metricId = _a.metricId;
    var metricStore = useStore().metricStore;
    var widget = useObserver(function () { return metricStore.instance; });
    var loading = useObserver(function () { return metricStore.isLoading; });
    var _b = __read(useState(!metricId || metricId === 'create'), 2), expanded = _b[0], setExpanded = _b[1];
    React.useEffect(function () {
        if (metricId && metricId !== 'create') {
            metricStore.fetch(metricId);
        }
        else {
            metricStore.init();
        }
    }, []);
    var onBackHandler = function () {
        if (dashboardId) {
            props.history.push(withSiteId("/dashboard/" + dashboardId, siteId));
        }
        else {
            props.history.push(withSiteId("/metrics", siteId));
        }
    };
    return useObserver(function () { return (React.createElement(Loader, { loading: loading },
        React.createElement("div", { className: "relative pb-10" },
            React.createElement(BackLink, { onClick: onBackHandler, vertical: true, className: "absolute", style: { left: '-50px', top: '0px' } }),
            React.createElement("div", { className: "bg-white rounded border" },
                React.createElement("div", { className: "p-4 flex justify-between items-center" },
                    React.createElement("h1", { className: "mb-0 text-2xl" },
                        React.createElement(WidgetName, { name: widget.name, onUpdate: function (name) { return metricStore.merge({ name: name }); }, canEdit: expanded })),
                    React.createElement("div", { className: "text-gray-600" },
                        React.createElement("div", { onClick: function () { return setExpanded(!expanded); }, className: "flex items-center cursor-pointer select-none" },
                            React.createElement("span", { className: "mr-2 color-teal" }, expanded ? 'Close' : 'Edit'),
                            React.createElement(Icon, { name: expanded ? 'chevron-up' : 'chevron-down', size: "16", color: "teal" })))),
                expanded && React.createElement(WidgetForm, __assign({ onDelete: onBackHandler }, props))),
            React.createElement(WidgetPreview, { className: "mt-8" }),
            React.createElement(WidgetSessions, { className: "mt-8" })))); });
}
export default WidgetView;
