import React from 'react';
import { connect } from 'react-redux';
import WidgetIcon from './WidgetIcon';
import { init as initAlert } from 'Duck/alerts';
import { useStore } from 'App/mstore';
function AlertButton(props) {
    var seriesId = props.seriesId, initAlert = props.initAlert;
    var dashboardStore = useStore().dashboardStore;
    var onClick = function () {
        initAlert({ query: { left: seriesId } });
        dashboardStore.updateKey('showAlertModal', true);
    };
    return (React.createElement(WidgetIcon, { className: "cursor-pointer", icon: "bell-plus", tooltip: "Set Alert", onClick: onClick }));
}
export default connect(null, { initAlert: initAlert })(AlertButton);
