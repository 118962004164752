var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { NoContent } from 'UI';
import { Table } from '../../common';
import { getRE } from 'App/utils';
import ImageInfo from './ImageInfo';
import MethodType from './MethodType';
import cn from 'classnames';
import stl from './callWithErrors.css';
var cols = [
    {
        key: 'method',
        title: 'Method',
        className: 'text-left',
        Component: MethodType,
        cellClass: 'ml-2',
        width: '8%',
    },
    {
        key: 'urlHostpath',
        title: 'Path',
        Component: ImageInfo,
        width: '40%',
    },
    {
        key: 'allRequests',
        title: 'Requests',
        className: 'text-left',
        width: '15%',
    },
    {
        key: '4xx',
        title: '4xx',
        className: 'text-left',
        width: '15%',
    },
    {
        key: '5xx',
        title: '5xx',
        className: 'text-left',
        width: '15%',
    }
];
function CallWithErrors(props) {
    var data = props.data, metric = props.metric;
    var _a = __read(React.useState(''), 2), search = _a[0], setSearch = _a[1];
    var test = function (value, serach) {
        if (value === void 0) { value = ''; }
        return getRE(serach, 'i').test(value);
    };
    var _data = search ? metric.data.chart.filter(function (i) { return test(i.urlHostpath, search); }) : metric.data.chart.images;
    var write = function (_a) {
        var _b = _a.target, name = _b.name, value = _b.value;
        setSearch(value);
    };
    return (React.createElement(NoContent, { size: "small", show: metric.data.chart.length === 0, style: { height: '240px' } },
        React.createElement("div", { style: { height: '240px' } },
            React.createElement("div", { className: cn(stl.topActions, 'py-3 flex text-right') },
                React.createElement("input", { disabled: metric.data.chart.length === 0, className: stl.searchField, name: "search", placeholder: "Filter by Path", onChange: write })),
            React.createElement(Table, { cols: cols, rows: _data }))));
}
export default CallWithErrors;
