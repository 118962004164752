import { runInAction, makeAutoObservable, observable } from 'mobx';
import { Map } from 'immutable';
import { Duration } from 'luxon';
var HASH_MOD = 1610612741;
var HASH_P = 53;
function hashString(s) {
    var mul = 1;
    var hash = 0;
    for (var i = 0; i < s.length; i++) {
        hash = (hash + s.charCodeAt(i) * mul) % HASH_MOD;
        mul = (mul * HASH_P) % HASH_MOD;
    }
    return hash;
}
var Session = /** @class */ (function () {
    function Session() {
        this.sessionId = "";
        this.viewed = false;
        this.duration = 0;
        this.metadata = Map();
        this.startedAt = 0;
        this.userBrowser = "";
        this.userOs = "";
        this.userId = "";
        this.userDeviceType = "";
        this.userCountry = "";
        this.eventsCount = 0;
        this.userNumericHash = 0;
        this.userDisplayName = "";
        makeAutoObservable(this, {
            sessionId: observable,
        });
    }
    Session.prototype.fromJson = function (session) {
        var _this = this;
        runInAction(function () {
            Object.keys(session).forEach(function (key) {
                _this[key] = session[key];
            });
            var startTs = session.startTs, timestamp = session.timestamp;
            var startedAt = +startTs || +timestamp;
            _this.sessionId = session.sessionId;
            _this.viewed = session.viewed;
            _this.duration = Duration.fromMillis(session.duration < 1000 ? 1000 : session.duration);
            _this.metadata = Map(session.metadata);
            _this.startedAt = startedAt;
            _this.userBrowser = session.userBrowser;
            _this.userOs = session.userOs;
            _this.userId = session.userId;
            _this.userDeviceType = session.userDeviceType;
            _this.eventsCount = session.eventsCount;
            _this.userCountry = session.userCountry;
            _this.userNumericHash = hashString(session.userId || session.userAnonymousId || session.userUuid || session.userID || session.userUUID || "");
            _this.userDisplayName = session.userId || session.userAnonymousId || session.userID || 'Anonymous User';
        });
        return this;
    };
    return Session;
}());
export default Session;
