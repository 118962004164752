var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
//@ts-nocheck
import React from 'react';
import { Icon } from 'UI';
import cn from 'classnames';
import { debounce } from 'App/utils';
import { Tooltip } from 'react-tippy';
export default function Pagination(props) {
    var page = props.page, totalPages = props.totalPages, onPageChange = props.onPageChange, _a = props.limit, limit = _a === void 0 ? 5 : _a, _b = props.debounceRequest, debounceRequest = _b === void 0 ? 0 : _b;
    var _c = __read(React.useState(page), 2), currentPage = _c[0], setCurrentPage = _c[1];
    React.useMemo(function () { return setCurrentPage(page); }, [page]);
    var debounceChange = React.useCallback(debounce(onPageChange, debounceRequest), []);
    var changePage = function (page) {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
            debounceChange(page);
        }
    };
    var isFirstPage = currentPage === 1;
    var isLastPage = currentPage === totalPages;
    return (React.createElement("div", { className: "flex items-center" },
        React.createElement(Tooltip, { arrow: true, sticky: true, title: "Previous Page", trigger: "mouseenter", hideOnClick: true },
            React.createElement("button", { className: cn("py-2 px-3", { "opacity-50 cursor-default": isFirstPage }), disabled: isFirstPage, onClick: function () { return changePage(currentPage - 1); } },
                React.createElement(Icon, { name: "chevron-left", size: "18", color: isFirstPage ? 'gray-medium' : 'teal' }))),
        React.createElement("span", { className: "mr-2 color-gray-medium" }, "Page"),
        React.createElement("input", { type: "number", className: cn("py-1 px-2 bg-white border border-gray-light rounded w-16", { "opacity-50 cursor-default": totalPages === 1 }), value: currentPage, min: 1, max: totalPages, onChange: function (e) { return changePage(parseInt(e.target.value)); } }),
        React.createElement("span", { className: "mx-3 color-gray-medium" }, "of"),
        React.createElement("span", null, totalPages),
        React.createElement(Tooltip, { arrow: true, sticky: true, title: "Next Page", trigger: "mouseenter", hideOnClick: true },
            React.createElement("button", { className: cn("py-2 px-3", { "opacity-50 cursor-default": isLastPage }), disabled: isLastPage, onClick: function () { return changePage(currentPage + 1); } },
                React.createElement(Icon, { name: "chevron-right", size: "18", color: isLastPage ? 'gray-medium' : 'teal' })))));
}
