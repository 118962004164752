import APIClient from 'App/api_client';
var DashboardService = /** @class */ (function () {
    function DashboardService(client) {
        this.client = client ? client : new APIClient();
    }
    DashboardService.prototype.initClient = function (client) {
        this.client = client || new APIClient();
    };
    /**
     * Get all widgets from a dashboard.
     * @param dashboardId Required
     * @returns
     */
    DashboardService.prototype.getWidgets = function (dashboardId) {
        return this.client.get("/dashboards/" + dashboardId + "/widgets")
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || []; });
    };
    /**
     * Get all dashboards.
     * @returns {Promise<any>}
     */
    DashboardService.prototype.getDashboards = function () {
        return this.client.get('/dashboards')
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || []; });
    };
    /**
     * Get a dashboard by dashboardId.
     * @param dashboardId
     * @returns {Promise<any>}
     */
    DashboardService.prototype.getDashboard = function (dashboardId) {
        return this.client.get('/dashboards/' + dashboardId)
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || {}; });
    };
    /**
     * Create or update a dashboard.
     * @param dashboard Required
     * @returns {Promise<any>}
     */
    DashboardService.prototype.saveDashboard = function (dashboard) {
        var data = dashboard.toJson();
        if (dashboard.dashboardId) {
            return this.client.put("/dashboards/" + dashboard.dashboardId, data)
                .then(function (response) { return response.json(); })
                .then(function (response) { return response.data || {}; });
        }
        else {
            return this.client.post('/dashboards', data)
                .then(function (response) { return response.json(); })
                .then(function (response) { return response.data || {}; });
        }
    };
    /**
     * Add a widget to a dashboard.
     * @param dashboard
     * @param metricIds
     * @returns
     */
    DashboardService.prototype.addWidget = function (dashboard, metricIds) {
        var data = dashboard.toJson();
        data.metrics = metricIds;
        return this.client.put("/dashboards/" + dashboard.dashboardId, data)
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || {}; });
    };
    /**
     * Delete a dashboard.
     * @param dashboardId
     * @returns {Promise<any>}
     */
    DashboardService.prototype.deleteDashboard = function (dashboardId) {
        return this.client.delete("/dashboards/" + dashboardId);
    };
    /**
     * Create a new Meitrc, if the dashboardId is not provided,
     * it will add the metric to the dashboard.
     * @param metric Required
     * @param dashboardId Optional
     * @returns {Promise<any>}
     */
    DashboardService.prototype.saveMetric = function (metric, dashboardId) {
        var data = metric.toJson();
        var path = dashboardId ? "/dashboards/" + dashboardId + "/metrics" : '/metrics';
        if (metric.widgetId) {
            return this.client.put(path + '/' + metric.widgetId, data);
        }
        else {
            return this.client.post(path, data);
        }
    };
    /**
     * Remove a widget from a dashboard.
     * @param dashboardId Required
     * @param widgetId Required
     * @returns {Promise<any>}
     */
    DashboardService.prototype.deleteWidget = function (dashboardId, widgetId) {
        return this.client.delete("/dashboards/" + dashboardId + "/widgets/" + widgetId);
    };
    /**
     * Add a widget to a dashboard.
     * @param dashboardId Required
     * @param widget Required
     * @returns {Promise<any>}
     */
    DashboardService.prototype.saveWidget = function (dashboardId, widget) {
        if (widget.widgetId) {
            return this.client.put("/dashboards/" + dashboardId + "/widgets/" + widget.widgetId, widget.toWidget())
                .then(function (response) { return response.json(); })
                .then(function (response) { return response.data || {}; });
        }
        return this.client.post("/dashboards/" + dashboardId + "/widgets", widget.toWidget())
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || {}; });
    };
    /**
     * Update the pinned status of a dashboard.
     * @param dashboardId
     * @returns
     */
    DashboardService.prototype.updatePinned = function (dashboardId) {
        return this.client.get("/dashboards/" + dashboardId + "/pin", {})
            .then(function (response) { return response.json(); });
    };
    return DashboardService;
}());
export default DashboardService;
