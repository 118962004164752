// import Filter from 'Types/filter';
import Filter from './filter';
import { makeAutoObservable, observable, action } from "mobx";
var FilterSeries = /** @class */ (function () {
    function FilterSeries() {
        this.seriesId = undefined;
        this.name = "Series 1";
        this.filter = new Filter();
        makeAutoObservable(this, {
            name: observable,
            filter: observable,
            update: action,
        });
    }
    Object.defineProperty(FilterSeries, "ID_KEY", {
        get: function () { return "seriesId"; },
        enumerable: false,
        configurable: true
    });
    FilterSeries.prototype.update = function (key, value) {
        this[key] = value;
    };
    FilterSeries.prototype.fromJson = function (json) {
        this.seriesId = json.seriesId;
        this.name = json.name;
        this.filter = new Filter().fromJson(json.filter);
        return this;
    };
    FilterSeries.prototype.toJson = function () {
        return {
            seriesId: this.seriesId,
            name: this.name,
            filter: this.filter.toJson(),
        };
    };
    return FilterSeries;
}());
export default FilterSeries;
