var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
//@ts-nocheck
import React, { Component, createContext } from 'react';
import Modal from './Modal';
var ModalContext = createContext({
    component: null,
    props: {},
    showModal: function (component, props) { },
    hideModal: function () { }
});
var ModalProvider = /** @class */ (function (_super) {
    __extends(ModalProvider, _super);
    function ModalProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleKeyDown = function (e) {
            if (e.keyCode === 27) {
                _this.hideModal();
            }
        };
        _this.showModal = function (component, props) {
            if (props === void 0) { props = {}; }
            _this.setState({
                component: component,
                props: props
            });
            document.addEventListener('keydown', _this.handleKeyDown);
        };
        _this.hideModal = function () {
            _this.setState({
                component: null,
                props: {}
            });
            document.removeEventListener('keydown', _this.handleKeyDown);
        };
        _this.state = {
            component: null,
            props: {},
            showModal: _this.showModal,
            hideModal: _this.hideModal
        };
        return _this;
    }
    ModalProvider.prototype.render = function () {
        return (React.createElement(ModalContext.Provider, { value: this.state },
            React.createElement(Modal, null),
            this.props.children));
    };
    return ModalProvider;
}(Component));
export { ModalProvider };
export var ModalConsumer = ModalContext.Consumer;
export var useModal = function () { return React.useContext(ModalContext); };
