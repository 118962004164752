var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeAutoObservable, runInAction, observable, action } from "mobx";
import FilterSeries from "./filterSeries";
import { DateTime } from 'luxon';
import { metricService } from "App/services";
import Session from "App/mstore/types/session";
var Widget = /** @class */ (function () {
    function Widget() {
        this.metricId = undefined;
        this.widgetId = undefined;
        this.name = "New Metric";
        this.metricType = "timeseries";
        this.metricOf = "sessionCount";
        this.metricValue = "";
        this.viewType = "lineChart";
        this.metricFormat = "sessionCount";
        this.series = [];
        this.sessions = [];
        this.isPublic = true;
        this.owner = "";
        this.lastModified = new Date();
        this.dashboards = [];
        this.dashboardIds = [];
        this.config = {};
        this.params = { density: 70 };
        this.sessionsLoading = false;
        this.position = 0;
        this.data = {
            chart: [],
            namesMap: {},
            avg: 0,
            percentiles: [],
        };
        this.isLoading = false;
        this.isValid = false;
        this.dashboardId = undefined;
        this.colSpan = 2;
        this.predefinedKey = '';
        makeAutoObservable(this, {
            sessionsLoading: observable,
            data: observable.ref,
            metricId: observable,
            widgetId: observable,
            name: observable,
            metricType: observable,
            metricOf: observable,
            position: observable,
            isLoading: observable,
            isValid: observable,
            dashboardId: observable,
            colSpan: observable,
            series: observable,
            addSeries: action,
            removeSeries: action,
            fromJson: action,
            toJson: action,
            validate: action,
            update: action,
            udpateKey: action,
        });
        var filterSeries = new FilterSeries();
        this.series.push(filterSeries);
    }
    Object.defineProperty(Widget, "ID_KEY", {
        get: function () { return "metricId"; },
        enumerable: false,
        configurable: true
    });
    Widget.prototype.udpateKey = function (key, value) {
        this[key] = value;
    };
    Widget.prototype.removeSeries = function (index) {
        this.series.splice(index, 1);
    };
    Widget.prototype.addSeries = function () {
        var series = new FilterSeries();
        series.name = "Series " + (this.series.length + 1);
        this.series.push(series);
    };
    Widget.prototype.fromJson = function (json) {
        var _this = this;
        json.config = json.config || {};
        runInAction(function () {
            _this.metricId = json.metricId;
            _this.widgetId = json.widgetId;
            _this.metricValue = json.metricValue;
            _this.metricOf = json.metricOf;
            _this.metricType = json.metricType;
            _this.metricFormat = json.metricFormat;
            _this.viewType = json.viewType;
            _this.name = json.name;
            _this.series = json.series ? json.series.map(function (series) { return new FilterSeries().fromJson(series); }) : [],
                _this.dashboards = json.dashboards;
            _this.owner = json.ownerEmail;
            _this.lastModified = json.editedAt || json.createdAt ? DateTime.fromMillis(json.editedAt || json.createdAt) : null;
            _this.config = json.config;
            _this.position = json.config.position;
            _this.predefinedKey = json.predefinedKey;
        });
        return this;
    };
    Widget.prototype.toWidget = function () {
        return {
            config: {
                position: this.position,
                col: this.config.col,
                row: this.config.row,
            }
        };
    };
    Widget.prototype.toJsonDrilldown = function () {
        return this.series.map(function (series) { return series.toJson(); });
    };
    Widget.prototype.toJson = function () {
        return {
            metricId: this.metricId,
            widgetId: this.widgetId,
            metricOf: this.metricOf,
            metricValue: this.metricValue,
            metricType: this.metricType,
            metricFormat: this.metricFormat,
            viewType: this.viewType,
            name: this.name,
            series: this.series.map(function (series) { return series.toJson(); }),
        };
    };
    Widget.prototype.validate = function () {
        this.isValid = this.name.length > 0;
    };
    Widget.prototype.update = function (data) {
        var _this = this;
        runInAction(function () {
            Object.assign(_this, data);
        });
    };
    Widget.prototype.exists = function () {
        return this.metricId !== undefined;
    };
    Widget.prototype.setData = function (data) {
        var _this = this;
        runInAction(function () {
            Object.assign(_this.data, data);
        });
    };
    Widget.prototype.fetchSessions = function (metricId, filter) {
        return new Promise(function (resolve, reject) {
            metricService.fetchSessions(metricId, filter).then(function (response) {
                resolve(response.map(function (cat) {
                    return __assign(__assign({}, cat), { sessions: cat.sessions.map(function (s) { return new Session().fromJson(s); }) });
                }));
            });
        });
    };
    return Widget;
}());
export default Widget;
