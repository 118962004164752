//@ts-nocheck
import React from 'react';
import { Icon } from 'UI';
import { Tooltip } from 'react-tippy';
function WidgetIcon(props) {
    var className = props.className, onClick = props.onClick, icon = props.icon, tooltip = props.tooltip;
    return (React.createElement(Tooltip, { arrow: true, size: "small", title: tooltip, position: "top" },
        React.createElement("div", { className: className, onClick: onClick },
            React.createElement(Icon, { name: icon, size: "14" }))));
}
export default WidgetIcon;
