var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import DashboardStore from './dashboardStore';
import MetricStore from './metricStore';
import APIClient from 'App/api_client';
import { dashboardService, metricService } from 'App/services';
var RootStore = /** @class */ (function () {
    function RootStore() {
        this.dashboardStore = new DashboardStore();
        this.metricStore = new MetricStore();
    }
    RootStore.prototype.initClient = function () {
        var client = new APIClient();
        dashboardService.initClient(client);
        metricService.initClient(client);
    };
    return RootStore;
}());
export { RootStore };
var StoreContext = React.createContext({});
export var StoreProvider = function (_a) {
    var children = _a.children, store = _a.store;
    return (React.createElement(StoreContext.Provider, { value: store }, children));
};
export var useStore = function () { return React.useContext(StoreContext); };
export var withStore = function (Component) { return function (props) {
    return React.createElement(Component, __assign({}, props, { mstore: useStore() }));
}; };
