var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { NoContent } from 'UI';
import { Styles, AvgLabel } from '../../common';
import { withRequest } from 'HOCs';
import { AreaChart, Area, CartesianGrid, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { toUnderscore } from 'App/utils';
var WIDGET_KEY = 'resourcesLoadingTime';
export var RESOURCE_OPTIONS = [
    { text: 'All', value: 'all', },
    { text: 'JS', value: "SCRIPT", },
    { text: 'CSS', value: "STYLESHEET", },
    { text: 'Fetch', value: "REQUEST", },
    { text: 'Image', value: "IMG", },
    { text: 'Media', value: "MEDIA", },
    { text: 'Other', value: "OTHER", },
];
function ResourceLoadingTime(props) {
    var data = props.data, optionsLoading = props.optionsLoading, metric = props.metric;
    var gradientDef = Styles.gradientDef();
    var _a = __read(React.useState(''), 2), autoCompleteSelected = _a[0], setSutoCompleteSelected = _a[1];
    var _b = __read(React.useState(''), 2), type = _b[0], setType = _b[1];
    var onSelect = function (params) {
        // const _params = { density: 70 }
        setSutoCompleteSelected(params.value);
        // TODO reload the data with new params;
        // this.props.fetchWidget(WIDGET_KEY, dashbaordStore.period, props.platform, { ..._params, url: params.value })
    };
    var writeOption = function (e, _a) {
        var name = _a.name, value = _a.value;
        // this.setState({ [name]: value })
        setType(value);
        var _params = { density: 70 }; // TODO reload the data with new params;
        // this.props.fetchWidget(WIDGET_KEY, this.props.period, this.props.platform, { ..._params, [ name ]: value === 'all' ? null : value  })
    };
    return (React.createElement(NoContent, { size: "small", show: metric.data.chart.length === 0 },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex items-center mb-3" },
                React.createElement(AvgLabel, { className: "ml-auto", text: "Avg", count: Math.round(data.avg), unit: "ms" })),
            React.createElement(ResponsiveContainer, { height: 207, width: "100%" },
                React.createElement(AreaChart, { data: metric.data.chart, margin: Styles.chartMargins },
                    gradientDef,
                    React.createElement(CartesianGrid, { strokeDasharray: "3 3", vertical: false, stroke: "#EEEEEE" }),
                    React.createElement(XAxis, __assign({}, Styles.xaxis, { dataKey: "time", interval: (metric.params.density / 7) })),
                    React.createElement(YAxis, __assign({}, Styles.yaxis, { allowDecimals: false, tickFormatter: function (val) { return Styles.tickFormatter(val); }, label: __assign(__assign({}, Styles.axisLabelLeft), { value: "Resource Fetch Time (ms)" }) })),
                    React.createElement(Tooltip, __assign({}, Styles.tooltip)),
                    React.createElement(Area, { name: "Avg", unit: " ms", type: "monotone", dataKey: "avg", stroke: Styles.colors[0], fillOpacity: 1, strokeWidth: 2, strokeOpacity: 0.8, fill: 'url(#colorCount)' }))))));
}
export default withRequest({
    dataName: "options",
    initialData: [],
    dataWrapper: function (data) { return data; },
    loadingName: 'optionsLoading',
    requestName: "fetchOptions",
    endpoint: '/dashboard/' + toUnderscore(WIDGET_KEY) + '/search',
    method: 'GET'
})(ResourceLoadingTime);
