var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import DropdownPlain from 'Shared/DropdownPlain';
import { metricTypes, metricOf, issueOptions } from 'App/constants/filterOptions';
import { FilterKey } from 'Types/filter/filterType';
import { useStore } from 'App/mstore';
import { useObserver } from 'mobx-react-lite';
import { Button, Icon } from 'UI';
import FilterSeries from '../FilterSeries';
import { confirm } from 'UI/Confirmation';
import { withSiteId, dashboardMetricDetails, metricDetails } from 'App/routes';
import DashboardSelectionModal from '../DashboardSelectionModal/DashboardSelectionModal';
function WidgetForm(props) {
    var _this = this;
    var _a = __read(useState(false), 2), showDashboardSelectionModal = _a[0], setShowDashboardSelectionModal = _a[1];
    var history = props.history, _b = props.match.params, siteId = _b.siteId, dashboardId = _b.dashboardId, metricId = _b.metricId;
    var _c = useStore(), metricStore = _c.metricStore, dashboardStore = _c.dashboardStore;
    var dashboards = dashboardStore.dashboards;
    var isSaving = useObserver(function () { return metricStore.isSaving; });
    var metric = useObserver(function () { return metricStore.instance; });
    var timeseriesOptions = metricOf.filter(function (i) { return i.type === 'timeseries'; });
    var tableOptions = metricOf.filter(function (i) { return i.type === 'table'; });
    var isTable = metric.metricType === 'table';
    var _issueOptions = [{ text: 'All', value: 'all' }].concat(issueOptions);
    var canAddToDashboard = metric.exists() && dashboards.length > 0;
    var canAddSeries = metric.series.length < 3;
    var write = function (_a) {
        var _b;
        var _c = _a.target, value = _c.value, name = _c.name;
        return metricStore.merge((_b = {}, _b[name] = value, _b));
    };
    var writeOption = function (e, _a) {
        var _b;
        var value = _a.value, name = _a.name;
        var obj = (_b = {}, _b[name] = value, _b);
        if (name === 'metricValue') {
            obj['metricValue'] = [value];
        }
        if (name === 'metricOf') {
            if (value === FilterKey.ISSUE) {
                obj['metricValue'] = ['all'];
            }
        }
        if (name === 'metricType') {
            if (value === 'timeseries') {
                obj['metricOf'] = timeseriesOptions[0].value;
                obj['viewType'] = 'lineChart';
            }
            else if (value === 'table') {
                obj['metricOf'] = tableOptions[0].value;
                obj['viewType'] = 'table';
            }
        }
        metricStore.merge(obj);
    };
    var onSave = function () {
        var wasCreating = !metric.exists();
        metricStore.save(metric, dashboardId).then(function (metric) {
            if (wasCreating) {
                if (parseInt(dashboardId) > 0) {
                    history.push(withSiteId(dashboardMetricDetails(parseInt(dashboardId), metric.metricId), siteId));
                }
                else {
                    history.push(withSiteId(metricDetails(metric.metricId), siteId));
                }
            }
        });
    };
    var onDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, confirm({
                        header: 'Confirm',
                        confirmButton: 'Yes, delete',
                        confirmation: "Are you sure you want to permanently delete this metric?"
                    })];
                case 1:
                    if (_a.sent()) {
                        metricStore.delete(metric).then(props.onDelete);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onObserveChanges = function () {
        // metricStore.fetchMetricChartData(metric);
    };
    return useObserver(function () { return (React.createElement("div", { className: "p-4" },
        React.createElement("div", { className: "form-group" },
            React.createElement("label", { className: "font-medium" }, "Metric Type"),
            React.createElement("div", { className: "flex items-center" },
                React.createElement(DropdownPlain, { name: "metricType", options: metricTypes, value: metric.metricType, onChange: writeOption }),
                metric.metricType === 'timeseries' && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "mx-3" }, "of"),
                    React.createElement(DropdownPlain, { name: "metricOf", options: timeseriesOptions, value: metric.metricOf, onChange: writeOption }))),
                metric.metricType === 'table' && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "mx-3" }, "of"),
                    React.createElement(DropdownPlain, { name: "metricOf", options: tableOptions, value: metric.metricOf, onChange: writeOption }))),
                metric.metricOf === FilterKey.ISSUE && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "mx-3" }, "issue type"),
                    React.createElement(DropdownPlain, { name: "metricValue", options: _issueOptions, value: metric.metricValue[0], onChange: writeOption }))),
                metric.metricType === 'table' && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "mx-3" }, "showing"),
                    React.createElement(DropdownPlain, { name: "metricFormat", options: [
                            { value: 'sessionCount', text: 'Session Count' },
                        ], value: metric.metricFormat, onChange: writeOption }))))),
        React.createElement("div", { className: "form-group" },
            React.createElement("div", { className: "font-medium items-center py-2" }, "" + (isTable ? 'Filter by' : 'Chart Series'),
                !isTable && (React.createElement(Button, { className: "ml-2", primary: true, plain: true, size: "small", onClick: function () { return metric.addSeries(); }, disabled: !canAddSeries }, "Add Series"))),
            metric.series.length > 0 && metric.series.slice(0, isTable ? 1 : metric.series.length).map(function (series, index) { return (React.createElement("div", { className: "mb-2" },
                React.createElement(FilterSeries, { hideHeader: isTable, seriesIndex: index, series: series, 
                    // onRemoveSeries={() => removeSeries(index)}
                    onRemoveSeries: function () { return metric.removeSeries(index); }, canDelete: metric.series.length > 1, emptyMessage: isTable ?
                        'Filter data using any event or attribute. Use Add Step button below to do so.' :
                        'Add user event or filter to define the series by clicking Add Step.' }))); })),
        React.createElement("div", { className: "form-groups flex items-center justify-between" },
            React.createElement(Button, { primary: true, size: "small", onClick: onSave, disabled: isSaving }, metric.exists() ? 'Update' : 'Create'),
            React.createElement("div", { className: "flex items-center" }, metric.exists() && (React.createElement(React.Fragment, null,
                React.createElement(Button, { plain: true, size: "small", onClick: onDelete, className: "flex items-center" },
                    React.createElement(Icon, { name: "trash", size: "14", className: "mr-2", color: "teal" }),
                    "Delete"),
                React.createElement(Button, { plain: true, size: "small", className: "flex items-center ml-2", onClick: function () { return setShowDashboardSelectionModal(true); }, disabled: !canAddToDashboard },
                    React.createElement(Icon, { name: "columns-gap", size: "14", className: "mr-2", color: "teal" }),
                    "Add to Dashboard"))))),
        canAddToDashboard && (React.createElement(DashboardSelectionModal, { metricId: metric.metricId, show: showDashboardSelectionModal, closeHandler: function () { return setShowDashboardSelectionModal(false); } })))); });
}
export default WidgetForm;
