import { makeAutoObservable, observable, action, computed } from "mobx";
import Widget from "./types/widget";
import { metricService } from "App/services";
import { toast } from 'react-toastify';
var MetricStore = /** @class */ (function () {
    function MetricStore() {
        this.isLoading = false;
        this.isSaving = false;
        this.metrics = [];
        this.instance = new Widget();
        this.page = 1;
        this.pageSize = 15;
        this.metricsSearch = "";
        this.sort = {};
        this.sessionsPage = 1;
        this.sessionsPageSize = 10;
        makeAutoObservable(this, {
            isLoading: observable,
            metrics: observable,
            instance: observable,
            page: observable,
            pageSize: observable,
            metricsSearch: observable,
            sort: observable,
            init: action,
            updateKey: action,
            merge: action,
            reset: action,
            addToList: action,
            updateInList: action,
            findById: action,
            removeById: action,
            save: action,
            fetchList: action,
            fetch: action,
            delete: action,
            paginatedList: computed,
        });
        // reaction(
        //     () => this.metricsSearch,
        //     (metricsSearch) => { // TODO filter the list for View
        //         this.page = 1
        //         this.paginatedList
        //     }
        // )
    }
    // State Actions
    MetricStore.prototype.init = function (metric) {
        this.instance.update(metric || new Widget());
    };
    MetricStore.prototype.updateKey = function (key, value) {
        this[key] = value;
    };
    MetricStore.prototype.merge = function (object) {
        Object.assign(this.instance, object);
    };
    MetricStore.prototype.reset = function (id) {
        var metric = this.findById(id);
        if (metric) {
            this.instance = metric;
        }
    };
    MetricStore.prototype.addToList = function (metric) {
        this.metrics.push(metric);
    };
    MetricStore.prototype.updateInList = function (metric) {
        var index = this.metrics.findIndex(function (m) { return m[Widget.ID_KEY] === metric[Widget.ID_KEY]; });
        if (index >= 0) {
            this.metrics[index] = metric;
        }
    };
    MetricStore.prototype.findById = function (id) {
        return this.metrics.find(function (m) { return m[Widget.ID_KEY] === id; });
    };
    MetricStore.prototype.removeById = function (id) {
        this.metrics = this.metrics.filter(function (m) { return m[Widget.ID_KEY] !== id; });
    };
    Object.defineProperty(MetricStore.prototype, "paginatedList", {
        get: function () {
            var start = (this.page - 1) * this.pageSize;
            var end = start + this.pageSize;
            return this.metrics.slice(start, end);
        },
        enumerable: false,
        configurable: true
    });
    // API Communication
    MetricStore.prototype.save = function (metric, dashboardId) {
        var _this = this;
        var wasCreating = !metric.exists();
        this.isSaving = true;
        return metricService.saveMetric(metric, dashboardId)
            .then(function (metric) {
            var _metric = new Widget().fromJson(metric);
            if (wasCreating) {
                toast.success('Metric created successfully');
                _this.addToList(_metric);
                _this.instance = _metric;
            }
            else {
                toast.success('Metric updated successfully');
                _this.updateInList(_metric);
            }
            return _metric;
        }).catch(function () {
            toast.error('Error saving metric');
        }).finally(function () {
            _this.isSaving = false;
        });
    };
    MetricStore.prototype.fetchList = function () {
        var _this = this;
        this.isLoading = true;
        return metricService.getMetrics()
            .then(function (metrics) {
            _this.metrics = metrics.map(function (m) { return new Widget().fromJson(m); });
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    MetricStore.prototype.fetch = function (id) {
        var _this = this;
        this.isLoading = true;
        return metricService.getMetric(id)
            .then(function (metric) {
            return _this.instance = new Widget().fromJson(metric);
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    MetricStore.prototype.delete = function (metric) {
        var _this = this;
        this.isSaving = true;
        return metricService.deleteMetric(metric[Widget.ID_KEY])
            .then(function () {
            _this.removeById(metric[Widget.ID_KEY]);
            toast.success('Metric deleted successfully');
        }).finally(function () {
            _this.isSaving = false;
        });
    };
    return MetricStore;
}());
export default MetricStore;
