import { makeAutoObservable, runInAction, observable, action } from "mobx";
import FilterItem from "./filterItem";
var Filter = /** @class */ (function () {
    function Filter() {
        this.filterId = '';
        this.name = '';
        this.filters = [];
        this.eventsOrder = 'then';
        this.startTimestamp = 0;
        this.endTimestamp = 0;
        makeAutoObservable(this, {
            filters: observable,
            eventsOrder: observable,
            startTimestamp: observable,
            endTimestamp: observable,
            addFilter: action,
            removeFilter: action,
            updateKey: action,
            merge: action,
        });
    }
    Object.defineProperty(Filter, "ID_KEY", {
        get: function () { return "filterId"; },
        enumerable: false,
        configurable: true
    });
    Filter.prototype.merge = function (filter) {
        var _this = this;
        runInAction(function () {
            Object.assign(_this, filter);
        });
    };
    Filter.prototype.addFilter = function (filter) {
        filter.value = [""];
        if (Array.isArray(filter.filters)) {
            filter.filters = filter.filters.map(function (i) {
                i.value = [""];
                return new FilterItem(i);
            });
        }
        this.filters.push(new FilterItem(filter));
    };
    Filter.prototype.updateFilter = function (index, filter) {
        this.filters[index] = new FilterItem(filter);
    };
    Filter.prototype.updateKey = function (key, value) {
        this[key] = value;
    };
    Filter.prototype.removeFilter = function (index) {
        this.filters.splice(index, 1);
    };
    Filter.prototype.fromJson = function (json) {
        this.name = json.name;
        this.filters = json.filters.map(function (i) { return new FilterItem().fromJson(i); });
        this.eventsOrder = json.eventsOrder;
        return this;
    };
    Filter.prototype.toJsonDrilldown = function () {
        var json = {
            name: this.name,
            filters: this.filters.map(function (i) { return i.toJson(); }),
            eventsOrder: this.eventsOrder,
            startTimestamp: this.startTimestamp,
            endTimestamp: this.endTimestamp,
        };
        return json;
    };
    Filter.prototype.toJson = function () {
        var json = {
            name: this.name,
            filters: this.filters.map(function (i) { return i.toJson(); }),
            eventsOrder: this.eventsOrder,
        };
        return json;
    };
    return Filter;
}());
export default Filter;
