import React from 'react';
import ErrorsByType from 'App/components/Dashboard/Widgets/PredefinedWidgets/ErrorsByType';
import ErrorsByOrigin from 'App/components/Dashboard/Widgets/PredefinedWidgets/ErrorsByOrigin';
import ErrorsPerDomain from 'App/components/Dashboard/Widgets/PredefinedWidgets/ErrorsPerDomain';
import { useObserver } from 'mobx-react-lite';
import SessionsAffectedByJSErrors from 'App/components/Dashboard/Widgets/PredefinedWidgets/SessionsAffectedByJSErrors';
import CallsErrors4xx from 'App/components/Dashboard/Widgets/PredefinedWidgets/CallsErrors4xx';
import CallsErrors5xx from 'App/components/Dashboard/Widgets/PredefinedWidgets/CallsErrors5xx';
import CPULoad from 'App/components/Dashboard/Widgets/PredefinedWidgets/CPULoad';
import Crashes from 'App/components/Dashboard/Widgets/PredefinedWidgets/Crashes';
import DomBuildingTime from 'App/components/Dashboard/Widgets/PredefinedWidgets/DomBuildingTime';
import FPS from 'App/components/Dashboard/Widgets/PredefinedWidgets/FPS';
import MemoryConsumption from 'App/components/Dashboard/Widgets/PredefinedWidgets/MemoryConsumption';
import ResponseTime from 'App/components/Dashboard/Widgets/PredefinedWidgets/ResponseTime';
import TimeToRender from 'App/components/Dashboard/Widgets/PredefinedWidgets/TimeToRender';
import SlowestDomains from 'App/components/Dashboard/Widgets/PredefinedWidgets/SlowestDomains';
import ResourceLoadedVsVisuallyComplete from 'App/components/Dashboard/Widgets/PredefinedWidgets/ResourceLoadedVsVisuallyComplete';
import SessionsImpactedBySlowRequests from 'App/components/Dashboard/Widgets/PredefinedWidgets/SessionsImpactedBySlowRequests';
import ResourceLoadingTime from 'App/components/Dashboard/Widgets/PredefinedWidgets/ResourceLoadingTime';
import BreakdownOfLoadedResources from 'App/components/Dashboard/Widgets/PredefinedWidgets/BreakdownOfLoadedResources';
import MissingResources from 'App/components/Dashboard/Widgets/PredefinedWidgets/MissingResources';
import ResourceLoadedVsResponseEnd from 'App/components/Dashboard/Widgets/PredefinedWidgets/ResourceLoadedVsResponseEnd';
import SessionsPerBrowser from 'App/components/Dashboard/Widgets/PredefinedWidgets/SessionsPerBrowser';
import CallWithErrors from '../../Widgets/PredefinedWidgets/CallWithErrors';
import SpeedIndexByLocation from '../../Widgets/PredefinedWidgets/SpeedIndexByLocation';
import SlowestResources from '../../Widgets/PredefinedWidgets/SlowestResources';
import ResponseTimeDistribution from '../../Widgets/PredefinedWidgets/ResponseTimeDistribution';
function WidgetPredefinedChart(props) {
    var data = props.data, predefinedKey = props.predefinedKey, metric = props.metric;
    var renderWidget = function () {
        switch (predefinedKey) {
            // ERRORS
            case 'errors_per_type':
                return React.createElement(ErrorsByType, { data: data, metric: metric });
            case 'errors_per_domains':
                return React.createElement(ErrorsPerDomain, { data: data, metric: metric });
            case 'resources_by_party':
                return React.createElement(ErrorsByOrigin, { data: data, metric: metric });
            case 'impacted_sessions_by_js_errors':
                return React.createElement(SessionsAffectedByJSErrors, { data: data, metric: metric });
            case 'domains_errors_4xx':
                return React.createElement(CallsErrors4xx, { data: data, metric: metric });
            case 'domains_errors_5xx':
                return React.createElement(CallsErrors5xx, { data: data, metric: metric });
            case 'calls_errors':
                return React.createElement(CallWithErrors, { data: data, metric: metric });
            // PERFORMANCE
            case 'impacted_sessions_by_slow_pages':
                return React.createElement(SessionsImpactedBySlowRequests, { data: data, metric: metric });
            case 'pages_response_time_distribution':
                return React.createElement(ResponseTimeDistribution, { data: data, metric: metric });
            case 'speed_location':
                return React.createElement(SpeedIndexByLocation, { metric: metric });
            case 'cpu':
                return React.createElement(CPULoad, { data: data, metric: metric });
            case 'crashes':
                return React.createElement(Crashes, { data: data, metric: metric });
            case 'pages_dom_buildtime':
                return React.createElement(DomBuildingTime, { data: data, metric: metric });
            case 'fps':
                return React.createElement(FPS, { data: data, metric: metric });
            case 'memory_consumption':
                return React.createElement(MemoryConsumption, { data: data, metric: metric });
            case 'pages_response_time':
                return React.createElement(ResponseTime, { data: data, metric: metric });
            case 'resources_vs_visually_complete':
                return React.createElement(ResourceLoadedVsVisuallyComplete, { data: data, metric: metric });
            case 'sessions_per_browser':
                return React.createElement(SessionsPerBrowser, { data: data, metric: metric });
            case 'slowest_domains':
                return React.createElement(SlowestDomains, { data: data, metric: metric });
            case 'time_to_render':
                return React.createElement(TimeToRender, { data: data, metric: metric });
            // Resources
            case 'resources_count_by_type':
                return React.createElement(BreakdownOfLoadedResources, { data: data, metric: metric });
            case 'missing_resources':
                return React.createElement(MissingResources, { data: data, metric: metric });
            case 'resource_type_vs_response_end':
                return React.createElement(ResourceLoadedVsResponseEnd, { data: data, metric: metric });
            case 'resources_loading_time':
                return React.createElement(ResourceLoadingTime, { data: data, metric: metric });
            case 'slowest_resources':
                return React.createElement(SlowestResources, { data: data, metric: metric });
            default:
                return React.createElement("div", { className: "h-40 color-red" }, "Widget not supported");
        }
    };
    return useObserver(function () { return (React.createElement(React.Fragment, null, renderWidget())); });
}
export default WidgetPredefinedChart;
