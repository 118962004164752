import React from 'react';
import { NoContent } from 'UI';
import { Table } from '../../common';
import { List } from 'immutable';
import Chart from './Chart';
import ResourceInfo from './ResourceInfo';
import CopyPath from './CopyPath';
var cols = [
    {
        key: 'resource',
        title: 'Resource',
        Component: ResourceInfo,
        width: '40%',
    },
    {
        key: 'sessions',
        title: 'Sessions',
        toText: function (count) { return "" + (count > 1000 ? Math.trunc(count / 1000) : count) + (count > 1000 ? 'k' : ''); },
        width: '20%',
    },
    {
        key: 'trend',
        title: 'Trend',
        Component: Chart,
        width: '20%',
    },
    {
        key: 'copy-path',
        title: '',
        Component: CopyPath,
        cellClass: 'invisible group-hover:visible text-right',
        width: '20%',
    }
];
function MissingResources(props) {
    var data = props.data, metric = props.metric;
    return (React.createElement(NoContent, { title: "No resources missing.", size: "small", show: metric.data.chart.length === 0 },
        React.createElement("div", { style: { height: '240px' } },
            React.createElement(Table, { small: true, cols: cols, rows: List(metric.data.chart), rowClass: "group" }))));
}
export default MissingResources;
