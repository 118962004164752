var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import FilterList from 'Shared/Filters/FilterList';
import { edit, updateSeries, addSeriesFilterFilter, removeSeriesFilterFilter, editSeriesFilterFilter, editSeriesFilter, } from 'Duck/customMetrics';
import { connect } from 'react-redux';
import { IconButton, Icon } from 'UI';
import FilterSelection from 'Shared/Filters/FilterSelection';
import SeriesName from './SeriesName';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
function FilterSeries(props) {
    var _a = props.observeChanges, observeChanges = _a === void 0 ? function () { } : _a, canDelete = props.canDelete, _b = props.hideHeader, hideHeader = _b === void 0 ? false : _b, _c = props.emptyMessage, emptyMessage = _c === void 0 ? 'Add user event or filter to define the series by clicking Add Step.' : _c;
    var _d = __read(useState(true), 2), expanded = _d[0], setExpanded = _d[1];
    var series = props.series, seriesIndex = props.seriesIndex;
    useEffect(observeChanges, [series.filter]);
    var onAddFilter = function (filter) {
        series.filter.addFilter(filter);
    };
    var onUpdateFilter = function (filterIndex, filter) {
        series.filter.updateFilter(filterIndex, filter);
    };
    var onChangeEventsOrder = function (e, _a) {
        var name = _a.name, value = _a.value;
        series.filter.updateKey(name, value);
    };
    var onRemoveFilter = function (filterIndex) {
        series.filter.removeFilter(filterIndex);
    };
    return (React.createElement("div", { className: "border rounded bg-white" },
        React.createElement("div", { className: cn("border-b px-5 h-12 flex items-center relative", { 'hidden': hideHeader }) },
            React.createElement("div", { className: "mr-auto" },
                React.createElement(SeriesName, { seriesIndex: seriesIndex, name: series.name, onUpdate: function (name) { return series.update('name', name); } })),
            React.createElement("div", { className: "flex items-center cursor-pointer" },
                React.createElement("div", { onClick: props.onRemoveSeries, className: cn("ml-3", { 'disabled': !canDelete }) },
                    React.createElement(Icon, { name: "trash", size: "16" })),
                React.createElement("div", { onClick: function () { return setExpanded(!expanded); }, className: "ml-3" },
                    React.createElement(Icon, { name: "chevron-down", size: "16" })))),
        expanded && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "p-5" }, series.filter.filters.length > 0 ? (React.createElement(FilterList, { filter: series.filter, onUpdateFilter: onUpdateFilter, onRemoveFilter: onRemoveFilter, onChangeEventsOrder: onChangeEventsOrder, observeChanges: observeChanges })) : (React.createElement("div", { className: "color-gray-medium" }, emptyMessage))),
            React.createElement("div", { className: "border-t h-12 flex items-center" },
                React.createElement("div", { className: "-mx-4 px-6" },
                    React.createElement(FilterSelection, { filter: undefined, onFilterClick: onAddFilter },
                        React.createElement(IconButton, { primaryText: true, label: "ADD STEP", icon: "plus" }))))))));
}
export default connect(null, {
    edit: edit,
    updateSeries: updateSeries,
    addSeriesFilterFilter: addSeriesFilterFilter,
    editSeriesFilterFilter: editSeriesFilterFilter,
    editSeriesFilter: editSeriesFilter,
    removeSeriesFilterFilter: removeSeriesFilterFilter,
})(observer(FilterSeries));
