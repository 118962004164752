var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { NoContent } from 'UI';
import { Styles } from '../../common';
import { CartesianGrid, Tooltip, LineChart, Line, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
function CallsErrors5xx(props) {
    var data = props.data, metric = props.metric;
    return (React.createElement(NoContent, { size: "small", show: metric.data.chart.length === 0, style: { height: '240px' } },
        React.createElement(ResponsiveContainer, { height: 240, width: "100%" },
            React.createElement(LineChart, { data: metric.data.chart, margin: Styles.chartMargins },
                React.createElement(CartesianGrid, { strokeDasharray: "3 3", vertical: false, stroke: "#EEEEEE" }),
                React.createElement(XAxis, __assign({}, Styles.xaxis, { dataKey: "time", interval: metric.params.density / 7 })),
                React.createElement(YAxis, __assign({}, Styles.yaxis, { label: __assign(__assign({}, Styles.axisLabelLeft), { value: "Number of Errors" }), allowDecimals: false })),
                React.createElement(Legend, null),
                React.createElement(Tooltip, __assign({}, Styles.tooltip)),
                Array.isArray(metric.data.namesMap) && metric.data.namesMap.map(function (key, index) { return (React.createElement(Line, { key: key, name: key, type: "monotone", dataKey: key, stroke: Styles.colors[index], fillOpacity: 1, strokeWidth: 2, strokeOpacity: 0.8, fill: "url(#colorCount)", dot: false })); })))));
}
export default CallsErrors5xx;
