var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { NoContent } from 'UI';
import { Styles, AvgLabel } from '../../common';
import { ComposedChart, Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, ReferenceLine, Tooltip } from 'recharts';
var PercentileLine = function (props) {
    var _a = props.viewBox, x = _a.x, y = _a.y, xoffset = props.xoffset, yheight = props.yheight, height = props.height, label = props.label;
    return (React.createElement("svg", null,
        React.createElement("line", __assign({ x1: x + xoffset, x2: x + xoffset, y1: height + 10, y2: 205 }, props)),
        React.createElement("text", { x: x + 5, y: height + 20, fontSize: "8", fontFamily: "Roboto", fill: "#000000", textAnchor: "start" }, label)));
};
function ResponseTimeDistribution(props) {
    var data = props.data, metric = props.metric;
    var colors = Styles.colors;
    return (React.createElement(NoContent, { size: "small", show: metric.data.chart.length === 0, style: { height: '240px' } },
        React.createElement("div", { className: "flex items-center justify-end mb-3" },
            React.createElement(AvgLabel, { text: "Avg", unit: "ms", className: "ml-3", count: metric.data.avg })),
        React.createElement("div", { className: "flex mb-4" },
            React.createElement(ResponsiveContainer, { height: 240, width: "100%" },
                React.createElement(ComposedChart, { data: metric.data.chart, margin: Styles.chartMargins, barSize: 50 },
                    React.createElement(CartesianGrid, { strokeDasharray: "3 3", vertical: false, stroke: "#EEEEEE" }),
                    React.createElement(XAxis, __assign({}, Styles.xaxis, { dataKey: "responseTime", label: __assign(__assign({}, Styles.axisLabelLeft), { angle: 0, offset: 0, value: "Page Response Time (ms)", style: { textAnchor: 'middle' }, position: "insideBottom" }) })),
                    React.createElement(YAxis, __assign({}, Styles.yaxis, { allowDecimals: false, label: __assign(__assign({}, Styles.axisLabelLeft), { value: "Number of Calls" }) })),
                    React.createElement(Bar, { minPointSize: 1, name: "Calls", dataKey: "count", stackId: "a", fill: colors[2], label: "Backend" }),
                    React.createElement(Tooltip, __assign({}, Styles.tooltip, { labelFormatter: function (val) { return 'Page Response Time: ' + val; } })),
                    metric.data.percentiles.map(function (item, i) { return (React.createElement(ReferenceLine, { key: i, label: React.createElement(PercentileLine, { xoffset: 0, 
                            // y={130}
                            height: i * 20, stroke: '#000', strokeWidth: 0.5, strokeOpacity: 1, label: item.percentile + "th Percentile (" + item.responseTime + "ms)" }), 
                        // allowDecimals={false}
                        x: item.responseTime, strokeWidth: 0, strokeOpacity: 1 })); }))),
            React.createElement(ResponsiveContainer, { height: 240, width: "10%" },
                React.createElement(BarChart, { data: metric.data.extremeValues, margin: Styles.chartMargins, barSize: 40 },
                    React.createElement(CartesianGrid, { strokeDasharray: "3 3", vertical: false, stroke: "#EEEEEE" }),
                    React.createElement(XAxis, __assign({}, Styles.xaxis, { dataKey: "time" })),
                    React.createElement(YAxis, __assign({ hide: true, type: "number", domain: [0, metric.data.total] }, Styles.yaxis, { allowDecimals: false })),
                    React.createElement(Tooltip, __assign({}, Styles.tooltip)),
                    React.createElement(Bar, { minPointSize: 1, name: "Extreme Values", dataKey: "count", stackId: "a", fill: colors[0] }))))));
}
export default ResponseTimeDistribution;
