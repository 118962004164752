var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef } from 'react';
import cn from 'classnames';
import { ItemMenu } from 'UI';
import { useDrag, useDrop } from 'react-dnd';
import WidgetChart from '../WidgetChart';
import { useObserver } from 'mobx-react-lite';
// import { confirm } from 'UI/Confirmation';
import { useStore } from 'App/mstore';
import { withRouter } from 'react-router-dom';
import { withSiteId, dashboardMetricDetails } from 'App/routes';
import TemplateOverlay from './TemplateOverlay';
import AlertButton from './AlertButton';
function WidgetWrapper(props) {
    var _this = this;
    var dashboardStore = useStore().dashboardStore;
    var _a = props.isWidget, isWidget = _a === void 0 ? false : _a, _b = props.active, active = _b === void 0 ? false : _b, _c = props.index, index = _c === void 0 ? 0 : _c, _d = props.moveListItem, moveListItem = _d === void 0 ? null : _d, _e = props.isPreview, isPreview = _e === void 0 ? false : _e, _f = props.isTemplate, isTemplate = _f === void 0 ? false : _f, dashboardId = props.dashboardId, siteId = props.siteId;
    var widget = useObserver(function () { return props.widget; });
    var isPredefined = widget.metricType === 'predefined';
    var dashboard = useObserver(function () { return dashboardStore.selectedDashboard; });
    var isOverviewWidget = widget.widgetType === 'predefined' && widget.viewType === 'overview';
    var _g = __read(useDrag({
        type: 'item',
        item: { index: index },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
            opacity: monitor.isDragging() ? 0.5 : 1,
        }); },
    }), 2), _h = _g[0], opacity = _h.opacity, isDragging = _h.isDragging, dragRef = _g[1];
    var _j = __read(useDrop({
        accept: 'item',
        drop: function (item) {
            if (item.index === index)
                return;
            moveListItem(item.index, index);
        },
        collect: function (monitor) { return ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }); },
    }), 2), _k = _j[0], isOver = _k.isOver, canDrop = _k.canDrop, dropRef = _j[1];
    var onDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dashboardStore.deleteDashboardWidget(dashboard === null || dashboard === void 0 ? void 0 : dashboard.dashboardId, widget.widgetId);
            return [2 /*return*/];
        });
    }); };
    var onChartClick = function () {
        if (!isWidget || isPredefined)
            return;
        props.history.push(withSiteId(dashboardMetricDetails(dashboard === null || dashboard === void 0 ? void 0 : dashboard.dashboardId, widget.metricId), siteId));
    };
    var ref = useRef(null);
    var dragDropRef = dragRef(dropRef(ref));
    return useObserver(function () { return (React.createElement("div", { className: cn("relative rounded bg-white border", 'col-span-' + widget.config.col, { "cursor-pointer": isTemplate }), style: {
            userSelect: 'none',
            opacity: isDragging ? 0.5 : 1,
            borderColor: (canDrop && isOver) || active ? '#394EFF' : (isPreview ? 'transparent' : '#EEEEEE'),
        }, ref: dragDropRef, onClick: props.onClick ? props.onClick : function () { } },
        isTemplate && React.createElement(TemplateOverlay, null),
        React.createElement("div", { className: cn("p-3 flex items-center justify-between", { "cursor-move": !isTemplate }) },
            React.createElement("h3", { className: "capitalize" }, widget.name),
            isWidget && (React.createElement("div", { className: "flex items-center" },
                !isPredefined && (React.createElement(React.Fragment, null,
                    React.createElement(AlertButton, { seriesId: widget.series[0] && widget.series[0].seriesId }),
                    React.createElement("div", { className: 'mx-2' }))),
                React.createElement(ItemMenu, { items: [
                        {
                            text: 'Edit', onClick: onChartClick,
                            disabled: widget.metricType === 'predefined',
                            disabledMessage: 'Cannot edit system generated metrics'
                        },
                        {
                            text: 'Remove from view',
                            onClick: onDelete
                        },
                    ] })))),
        React.createElement("div", { className: "px-4", onClick: onChartClick },
            React.createElement(WidgetChart, { metric: widget, isWidget: isWidget })))); });
}
export default withRouter(WidgetWrapper);
