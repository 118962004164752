import { makeAutoObservable, observable, action } from "mobx";
import { FilterType } from 'Types/filter/filterType';
import { filtersMap } from 'Types/filter/newFilter';
var FilterItem = /** @class */ (function () {
    function FilterItem(data) {
        if (data === void 0) { data = {}; }
        this.type = '';
        this.key = '';
        this.label = '';
        this.value = [""];
        this.isEvent = false;
        this.operator = '';
        this.source = '';
        this.filters = [];
        this.operatorOptions = [];
        this.options = [];
        makeAutoObservable(this, {
            type: observable,
            key: observable,
            value: observable,
            operator: observable,
            source: observable,
            filters: observable,
            merge: action
        });
        if (Array.isArray(data.filters)) {
            data.filters = data.filters.map(function (i) {
                return new FilterItem(i);
            });
        }
        this.merge(data);
    }
    FilterItem.prototype.merge = function (data) {
        var _this = this;
        Object.keys(data).forEach(function (key) {
            _this[key] = data[key];
        });
    };
    FilterItem.prototype.fromJson = function (json, mainFilterKey) {
        if (mainFilterKey === void 0) { mainFilterKey = ''; }
        var _filter = filtersMap[json.type] || {};
        if (mainFilterKey) {
            var mainFilter = filtersMap[mainFilterKey];
            var subFilterMap_1 = {};
            mainFilter.filters.forEach(function (option) {
                subFilterMap_1[option.key] = option;
            });
            _filter = subFilterMap_1[json.type];
        }
        this.type = _filter.type;
        this.key = _filter.key;
        this.label = _filter.label;
        this.operatorOptions = _filter.operatorOptions;
        this.options = _filter.options;
        this.isEvent = _filter.isEvent;
        this.value = json.value.length === 0 || !json.value ? [""] : json.value,
            this.operator = json.operator;
        this.filters = _filter.type === FilterType.SUB_FILTERS && json.filters ? json.filters.map(function (i) { return new FilterItem().fromJson(i, json.type); }) : [];
        return this;
    };
    FilterItem.prototype.toJson = function () {
        var json = {
            type: this.key,
            isEvent: this.isEvent,
            value: this.value,
            operator: this.operator,
            source: this.source,
            filters: Array.isArray(this.filters) ? this.filters.map(function (i) { return i.toJson(); }) : [],
        };
        return json;
    };
    return FilterItem;
}());
export default FilterItem;
