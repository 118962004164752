var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import FilterOperator from '../FilterOperator';
import FilterSelection from '../FilterSelection';
import FilterValue from '../FilterValue';
import { Icon } from 'UI';
import FilterSource from '../FilterSource';
import { FilterKey, FilterType } from 'App/types/filter/filterType';
import SubFilterItem from '../SubFilterItem';
function FilterItem(props) {
    var _a = props.isFilter, isFilter = _a === void 0 ? false : _a, filterIndex = props.filterIndex, filter = props.filter, saveRequestPayloads = props.saveRequestPayloads;
    var canShowValues = !(filter.operator === "isAny" || filter.operator === "onAny" || filter.operator === "isUndefined");
    var isSubFilter = filter.type === FilterType.SUB_FILTERS;
    var replaceFilter = function (filter) {
        props.onUpdate(__assign(__assign({}, filter), { value: [""], filters: filter.filters ? filter.filters.map(function (i) { return (__assign(__assign({}, i), { value: [""] })); }) : [] }));
    };
    var onOperatorChange = function (e, _a) {
        var name = _a.name, value = _a.value;
        props.onUpdate(__assign(__assign({}, filter), { operator: value }));
    };
    var onSourceOperatorChange = function (e, _a) {
        var name = _a.name, value = _a.value;
        props.onUpdate(__assign(__assign({}, filter), { sourceOperator: value }));
    };
    var onUpdateSubFilter = function (subFilter, subFilterIndex) {
        props.onUpdate(__assign(__assign({}, filter), { filters: filter.filters.map(function (i, index) {
                if (index === subFilterIndex) {
                    return subFilter;
                }
                return i;
            }) }));
    };
    return (React.createElement("div", { className: "flex items-center hover:bg-active-blue -mx-5 px-5 py-2" },
        React.createElement("div", { className: "flex items-start w-full" },
            !isFilter && React.createElement("div", { className: "mt-1 flex-shrink-0 border w-6 h-6 text-xs flex justify-center rounded-full bg-gray-light-shade mr-2" }, filterIndex + 1),
            React.createElement(FilterSelection, { filter: filter, onFilterClick: replaceFilter }),
            filter.hasSource && (React.createElement(React.Fragment, null,
                React.createElement(FilterOperator, { options: filter.sourceOperatorOptions, onChange: onSourceOperatorChange, className: "mx-2 flex-shrink-0", value: filter.sourceOperator }),
                React.createElement(FilterSource, { filter: filter, onUpdate: props.onUpdate }))),
            !isSubFilter && (React.createElement(React.Fragment, null,
                React.createElement(FilterOperator, { options: filter.operatorOptions, onChange: onOperatorChange, className: "mx-2 flex-shrink-0", value: filter.operator }),
                canShowValues && (React.createElement(FilterValue, { filter: filter, onUpdate: props.onUpdate })))),
            isSubFilter && (React.createElement("div", { className: "grid grid-col ml-3 w-full" }, filter.filters.filter(function (i) { return (i.key !== FilterKey.FETCH_REQUEST_BODY && i.key !== FilterKey.FETCH_RESPONSE_BODY) || saveRequestPayloads; }).map(function (subFilter, subFilterIndex) { return (React.createElement(SubFilterItem, { filterIndex: subFilterIndex, filter: subFilter, onUpdate: function (f) { return onUpdateSubFilter(f, subFilterIndex); }, onRemoveFilter: props.onRemoveFilter })); })))),
        React.createElement("div", { className: "flex flex-shrink-0 self-start mt-1 ml-auto px-2" },
            React.createElement("div", { className: "cursor-pointer p-1", onClick: props.onRemoveFilter },
                React.createElement(Icon, { name: "trash", size: "14" })))));
}
export default FilterItem;
