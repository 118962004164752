var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef, useEffect } from 'react';
import CustomMetriLineChart from 'App/components/Dashboard/Widgets/CustomMetricsWidgets/CustomMetriLineChart';
import CustomMetricPercentage from 'App/components/Dashboard/Widgets/CustomMetricsWidgets/CustomMetricPercentage';
import CustomMetricTable from 'App/components/Dashboard/Widgets/CustomMetricsWidgets/CustomMetricTable';
import CustomMetricPieChart from 'App/components/Dashboard/Widgets/CustomMetricsWidgets/CustomMetricPieChart';
import { Styles } from 'App/components/Dashboard/Widgets/common';
import { observer, useObserver } from 'mobx-react-lite';
import { Loader } from 'UI';
import { useStore } from 'App/mstore';
import WidgetPredefinedChart from '../WidgetPredefinedChart';
import CustomMetricOverviewChart from 'App/components/Dashboard/Widgets/CustomMetricsWidgets/CustomMetricOverviewChart';
import { getStartAndEndTimestampsByDensity } from 'Types/dashboard/helper';
import { debounce } from 'App/utils';
function WidgetChart(props) {
    var _a = props.isWidget, isWidget = _a === void 0 ? false : _a, metric = props.metric;
    var _b = useStore(), dashboardStore = _b.dashboardStore, metricStore = _b.metricStore;
    var _metric = useObserver(function () { return metricStore.instance; });
    var period = useObserver(function () { return dashboardStore.period; });
    var drillDownFilter = useObserver(function () { return dashboardStore.drillDownFilter; });
    var colors = Styles.customMetricColors;
    var _c = __read(useState(true), 2), loading = _c[0], setLoading = _c[1];
    var isOverviewWidget = metric.metricType === 'predefined' && metric.viewType === 'overview';
    var params = { density: isOverviewWidget ? 7 : 70 };
    var metricParams = __assign({}, params);
    var prevMetricRef = useRef();
    var _d = __read(useState(metric.data), 2), data = _d[0], setData = _d[1];
    var isTableWidget = metric.metricType === 'table' && metric.viewType === 'table';
    var isPieChart = metric.metricType === 'table' && metric.viewType === 'pieChart';
    var onChartClick = function (event) {
        if (event) {
            if (isTableWidget || isPieChart) {
                var periodTimestamps = period.toTimestamps();
                drillDownFilter.merge({
                    filters: event,
                    startTimestamp: periodTimestamps.startTimestamp,
                    endTimestamp: periodTimestamps.endTimestamp,
                });
            }
            else {
                var payload = event.activePayload[0].payload;
                var timestamp = payload.timestamp;
                var periodTimestamps = getStartAndEndTimestampsByDensity(timestamp, period.start, period.end, params.density);
                drillDownFilter.merge({
                    startTimestamp: periodTimestamps.startTimestamp,
                    endTimestamp: periodTimestamps.endTimestamp,
                });
            }
        }
    };
    var depsString = JSON.stringify(_metric.series);
    var fetchMetricChartData = function (metric, payload, isWidget) {
        setLoading(true);
        dashboardStore.fetchMetricChartData(metric, payload, isWidget).then(function (res) {
            setData(res);
        }).finally(function () {
            setLoading(false);
        });
    };
    var debounceRequest = React.useCallback(debounce(fetchMetricChartData, 500), []);
    useEffect(function () {
        if (prevMetricRef.current && prevMetricRef.current.name !== metric.name) {
            prevMetricRef.current = metric;
            return;
        }
        ;
        prevMetricRef.current = metric;
        var payload = isWidget ? __assign({}, params) : __assign(__assign({}, metricParams), metric.toJson());
        debounceRequest(metric, payload, isWidget);
    }, [period, depsString]);
    var renderChart = function () {
        var metricType = metric.metricType, viewType = metric.viewType;
        if (metricType === 'predefined') {
            if (isOverviewWidget) {
                return React.createElement(CustomMetricOverviewChart, { data: data });
            }
            return React.createElement(WidgetPredefinedChart, { metric: metric, data: data, predefinedKey: metric.predefinedKey });
        }
        if (metricType === 'timeseries') {
            if (viewType === 'lineChart') {
                return (React.createElement(CustomMetriLineChart, { data: data, colors: colors, params: params, onClick: onChartClick }));
            }
            else if (viewType === 'progress') {
                return (React.createElement(CustomMetricPercentage, { data: data[0], colors: colors, params: params }));
            }
        }
        if (metricType === 'table') {
            if (viewType === 'table') {
                return React.createElement(CustomMetricTable, { metric: metric, data: data[0], onClick: onChartClick });
            }
            else if (viewType === 'pieChart') {
                return (React.createElement(CustomMetricPieChart, { metric: metric, data: data[0], colors: colors, params: params, onClick: onChartClick }));
            }
        }
        return React.createElement("div", null, "Unknown");
    };
    return useObserver(function () { return (React.createElement(Loader, { loading: loading, size: "small", style: { height: (isOverviewWidget ? 100 : 240) + "px" } }, renderChart())); });
}
export default observer(WidgetChart);
