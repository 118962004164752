var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { NoContent } from 'UI';
import { Styles, AvgLabel } from '../../common';
import Scale from './Scale';
import { observer } from 'mobx-react-lite';
import { numberWithCommas, positionOfTheNumber } from 'App/utils';
import WorldMap from "@svg-maps/world";
import { SVGMap } from "react-svg-map";
import stl from './SpeedIndexByLocation.css';
import cn from 'classnames';
function SpeedIndexByLocation(props) {
    var metric = props.metric;
    var wrapper = React.useRef(null);
    var map = null;
    var _a = __read(React.useState({ display: 'none' }), 2), tooltipStyle = _a[0], setTooltipStyle = _a[1];
    var _b = __read(React.useState(null), 2), pointedLocation = _b[0], setPointedLocation = _b[1];
    var dataMap = React.useMemo(function () {
        var data = {};
        var max = metric.data.chart.reduce(function (acc, item) { return Math.max(acc, item.avg); }, 0);
        var min = metric.data.chart.reduce(function (acc, item) { return Math.min(acc, item.avg); }, 0);
        metric.data.chart.forEach(function (item) {
            item.perNumber = positionOfTheNumber(min, max, item.avg, 5);
            data[item.userCountry.toLowerCase()] = item;
        });
        return data;
    }, []);
    var getLocationClassName = function (location, index) {
        var i = (dataMap[location.id] ? dataMap[location.id].perNumber : 0);
        var cls = stl["heat_index" + i];
        return cn(stl.location, cls);
    };
    var getLocationName = function (event) {
        if (!event)
            return null;
        var id = event.target.attributes.id.value;
        var name = event.target.attributes.name.value;
        var percentage = dataMap[id] ? dataMap[id].perNumber : 0;
        return { name: name, id: id, percentage: percentage };
    };
    var handleLocationMouseOver = function (event) {
        var pointedLocation = getLocationName(event);
        setPointedLocation(pointedLocation);
    };
    var handleLocationMouseOut = function () {
        setTooltipStyle({ display: 'none' });
        setPointedLocation(null);
    };
    var handleLocationMouseMove = function (event) {
        var tooltipStyle = {
            display: 'block',
            top: event.clientY + 10,
            left: event.clientX - 100
        };
        setTooltipStyle(tooltipStyle);
    };
    return (React.createElement(NoContent, { size: "small", show: false, style: { height: '240px' }, className: "relative" },
        React.createElement("div", { className: "absolute right-0 mr-4 top=0 w-full flex justify-end" },
            React.createElement(AvgLabel, { text: "Avg", count: Math.round(metric.data.avg), unit: "ms" })),
        React.createElement(Scale, { colors: Styles.colors }),
        React.createElement("div", { className: "map-target" }),
        React.createElement("div", { style: {
                height: '234px',
                width: '100%',
                margin: '0 auto',
                display: 'flex',
            }, ref: wrapper },
            React.createElement(SVGMap, { map: WorldMap, className: stl.maps, locationClassName: getLocationClassName, onLocationMouseOver: handleLocationMouseOver, onLocationMouseOut: handleLocationMouseOut, onLocationMouseMove: handleLocationMouseMove })),
        React.createElement("div", { className: stl.tooltip, style: tooltipStyle }, pointedLocation && (React.createElement(React.Fragment, null,
            React.createElement("div", null, pointedLocation.name),
            React.createElement("div", null,
                "Avg: ",
                React.createElement("strong", null, dataMap[pointedLocation.id] ? numberWithCommas(parseInt(dataMap[pointedLocation.id].avg)) : 0)))))));
}
export default observer(SpeedIndexByLocation);
