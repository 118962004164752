var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { NoContent } from 'UI';
import { Styles, AvgLabel } from '../../common';
import { AreaChart, Area, CartesianGrid, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
function FPS(props) {
    var data = props.data, metric = props.metric;
    var gradientDef = Styles.gradientDef();
    return (React.createElement(NoContent, { size: "small", show: metric.data.chart.length === 0 },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex items-center justify-end mb-3" },
                React.createElement(AvgLabel, { text: "Avg", className: "ml-3", count: data.avgFps })),
            React.createElement(ResponsiveContainer, { height: 207, width: "100%" },
                React.createElement(AreaChart, { data: metric.data.chart, margin: Styles.chartMargins },
                    gradientDef,
                    React.createElement(CartesianGrid, { strokeDasharray: "3 3", vertical: false, stroke: "#EEEEEE" }),
                    React.createElement(XAxis, __assign({}, Styles.xaxis, { dataKey: "time", interval: (metric.params.density / 7) })),
                    React.createElement(YAxis, __assign({}, Styles.yaxis, { allowDecimals: false, tickFormatter: function (val) { return Styles.tickFormatter(val); }, label: __assign(__assign({}, Styles.axisLabelLeft), { value: "Frames Per Second" }) })),
                    React.createElement(Tooltip, __assign({}, Styles.tooltip)),
                    React.createElement(Area, { name: "Avg", type: "monotone", dataKey: "value", stroke: Styles.colors[0], fillOpacity: 1, strokeWidth: 2, strokeOpacity: 0.8, fill: 'url(#colorCount)' }))))));
}
export default FPS;
