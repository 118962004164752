import React, { useEffect } from 'react';
import FilterItem from '../FilterItem';
import { SegmentSelection, Popup } from 'UI';
import { List } from 'immutable';
import { useObserver } from 'mobx-react-lite';
function FilterList(props) {
    var _a = props.observeChanges, observeChanges = _a === void 0 ? function () { } : _a, filter = props.filter, _b = props.hideEventsOrder, hideEventsOrder = _b === void 0 ? false : _b, saveRequestPayloads = props.saveRequestPayloads;
    var filters = List(filter.filters);
    var hasEvents = filters.filter(function (i) { return i.isEvent; }).size > 0;
    var hasFilters = filters.filter(function (i) { return !i.isEvent; }).size > 0;
    var rowIndex = 0;
    useEffect(observeChanges, [filters]);
    var onRemoveFilter = function (filterIndex) {
        props.onRemoveFilter(filterIndex);
    };
    return useObserver(function () { return (React.createElement("div", { className: "flex flex-col" },
        hasEvents && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex items-center mb-2" },
                React.createElement("div", { className: "text-sm color-gray-medium mr-auto" }, "EVENTS"),
                !hideEventsOrder && (React.createElement("div", { className: "flex items-center" },
                    React.createElement("div", { className: "mr-2 color-gray-medium text-sm", style: { textDecoration: 'underline dotted' } },
                        React.createElement(Popup, { trigger: React.createElement("div", null, "Events Order"), content: "Select the operator to be applied between events in your search.", size: "tiny", inverted: true, position: "top center" })),
                    React.createElement(SegmentSelection, { primary: true, name: "eventsOrder", extraSmall: true, onSelect: props.onChangeEventsOrder, value: { value: filter.eventsOrder }, list: [
                            { name: 'THEN', value: 'then' },
                            { name: 'AND', value: 'and' },
                            { name: 'OR', value: 'or' },
                        ] })))),
            filters.map(function (filter, filterIndex) { return filter.isEvent ? (React.createElement(FilterItem, { key: filterIndex, filterIndex: rowIndex++, filter: filter, onUpdate: function (filter) { return props.onUpdateFilter(filterIndex, filter); }, onRemoveFilter: function () { return onRemoveFilter(filterIndex); }, saveRequestPayloads: saveRequestPayloads })) : null; }),
            React.createElement("div", { className: 'mb-2' }))),
        hasFilters && (React.createElement(React.Fragment, null,
            hasEvents && React.createElement("div", { className: 'border-t -mx-5 mb-4' }),
            React.createElement("div", { className: "mb-2 text-sm color-gray-medium mr-auto" }, "FILTERS"),
            filters.map(function (filter, filterIndex) { return !filter.isEvent ? (React.createElement(FilterItem, { key: filterIndex, isFilter: true, filterIndex: filterIndex, filter: filter, onUpdate: function (filter) { return props.onUpdateFilter(filterIndex, filter); }, onRemoveFilter: function () { return onRemoveFilter(filterIndex); } })) : null; }))))); });
}
export default FilterList;
