import React from 'react';
import { NoContent } from 'UI';
import { Table } from '../../common';
import { List } from 'immutable';
import { numberWithCommas } from 'App/utils';
import Chart from './Chart';
import ImageInfo from './ImageInfo';
import ResourceType from './ResourceType';
import CopyPath from './CopyPath';
export var RESOURCE_OPTIONS = [
    { text: 'All', value: 'ALL', },
    { text: 'CSS', value: 'STYLESHEET', },
    { text: 'JS', value: 'SCRIPT', },
];
var cols = [
    {
        key: 'type',
        title: 'Type',
        Component: ResourceType,
        className: 'text-center justify-center',
        cellClass: 'ml-2',
        width: '8%',
    },
    {
        key: 'name',
        title: 'File Name',
        Component: ImageInfo,
        cellClass: '-ml-2',
        width: '40%',
    },
    {
        key: 'avg',
        title: 'Load Time',
        toText: function (avg) { return (avg ? numberWithCommas(Math.trunc(avg)) : 0) + " ms"; },
        className: 'justify-center',
        width: '15%',
    },
    {
        key: 'trend',
        title: 'Trend',
        Component: Chart,
        width: '15%',
    },
    {
        key: 'copy-path',
        title: '',
        Component: CopyPath,
        cellClass: 'invisible group-hover:visible text-right',
        width: '15%',
    }
];
function SlowestResources(props) {
    var data = props.data, metric = props.metric;
    return (React.createElement(NoContent, { title: "No resources missing.", size: "small", show: metric.data.chart.length === 0 },
        React.createElement("div", { style: { height: '240px', marginBottom: '10px' } },
            React.createElement(Table, { small: true, cols: cols, rows: List(metric.data.chart), rowClass: "group" }))));
}
export default SlowestResources;
