import Widget from "App/mstore/types/widget";
import APIClient from 'App/api_client';
var MetricService = /** @class */ (function () {
    function MetricService(client) {
        this.client = client ? client : new APIClient();
    }
    MetricService.prototype.initClient = function (client) {
        this.client = client || new APIClient();
    };
    /**
     * Get all metrics.
     * @returns {Promise<any>}
     */
    MetricService.prototype.getMetrics = function () {
        return this.client.get('/metrics')
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || []; });
    };
    /**
     * Get a metric by metricId.
     * @param metricId
     * @returns {Promise<any>}
     */
    MetricService.prototype.getMetric = function (metricId) {
        return this.client.get('/metrics/' + metricId)
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || {}; });
    };
    /**
     * Save a metric.
     * @param metric
     * @returns
     */
    MetricService.prototype.saveMetric = function (metric, dashboardId) {
        var data = metric.toJson();
        var isCreating = !data[Widget.ID_KEY];
        var method = isCreating ? 'post' : 'put';
        var url = isCreating ? '/metrics' : '/metrics/' + data[Widget.ID_KEY];
        return this.client[method](url, data)
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || {}; });
    };
    /**
     * Delete a metric.
     * @param metricId
     * @returns {Promise<any>}
     */
    MetricService.prototype.deleteMetric = function (metricId) {
        return this.client.delete('/metrics/' + metricId)
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data; });
    };
    /**
     * Get all templates.
     * @returns {Promise<any>}
     */
    MetricService.prototype.getTemplates = function () {
        return this.client.get('/metrics/templates')
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || []; });
    };
    MetricService.prototype.getMetricChartData = function (metric, data, isWidget) {
        if (isWidget === void 0) { isWidget = false; }
        var path = isWidget ? "/metrics/" + metric.metricId + "/chart" : "/metrics/try";
        return this.client.post(path, data)
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || {}; });
    };
    /**
     * Fetch sessions from the server.
     * @param filter
     * @returns
     */
    MetricService.prototype.fetchSessions = function (metricId, filter) {
        return this.client.post(metricId ? "/metrics/" + metricId + "/sessions" : '/metrics/try/sessions', filter)
            .then(function (response) { return response.json(); })
            .then(function (response) { return response.data || []; });
    };
    return MetricService;
}());
export default MetricService;
