import React from 'react';
import cn from 'classnames';
import WidgetWrapper from '../WidgetWrapper';
import { useStore } from 'App/mstore';
import { SegmentSelection } from 'UI';
import DateRange from 'Shared/DateRange';
import { useObserver } from 'mobx-react-lite';
function WidgetPreview(props) {
    var _a = props.className, className = _a === void 0 ? '' : _a;
    var _b = useStore(), metricStore = _b.metricStore, dashboardStore = _b.dashboardStore;
    var period = useObserver(function () { return dashboardStore.period; });
    var metric = useObserver(function () { return metricStore.instance; });
    var isTimeSeries = metric.metricType === 'timeseries';
    var isTable = metric.metricType === 'table';
    var chagneViewType = function (e, _a) {
        var _b;
        var name = _a.name, value = _a.value;
        metric.update((_b = {}, _b[name] = value, _b));
    };
    return useObserver(function () { return (React.createElement("div", { className: cn(className) },
        React.createElement("div", { className: "flex items-center justify-between" },
            React.createElement("h2", { className: "text-2xl" }, "Trend"),
            React.createElement("div", { className: "flex items-center" },
                isTimeSeries && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "color-gray-medium mr-2" }, "Visualization"),
                    React.createElement(SegmentSelection, { name: "viewType", className: "my-3", primary: true, icons: true, onSelect: chagneViewType, value: { value: metric.viewType }, list: [
                            { value: 'lineChart', name: 'Chart', icon: 'graph-up-arrow' },
                            { value: 'progress', name: 'Progress', icon: 'hash' },
                        ] }))),
                isTable && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "mr-1 color-gray-medium" }, "Visualization"),
                    React.createElement(SegmentSelection, { name: "viewType", className: "my-3", primary: true, icons: true, onSelect: chagneViewType, value: { value: metric.viewType }, list: [
                            { value: 'table', name: 'Table', icon: 'table' },
                            { value: 'pieChart', name: 'Chart', icon: 'pie-chart-fill' },
                        ] }))),
                React.createElement("div", { className: "mx-4" }),
                React.createElement("span", { className: "mr-1 color-gray-medium" }, "Time Range"),
                React.createElement(DateRange, { rangeValue: period.rangeName, startDate: period.startDate, endDate: period.endDate, onDateChange: function (period) { return dashboardStore.setPeriod(period); }, customRangeRight: true, direction: "left" }))),
        React.createElement("div", { className: "bg-white rounded p-4" },
            React.createElement(WidgetWrapper, { widget: metric, isPreview: true, isWidget: false })))); });
}
export default WidgetPreview;
