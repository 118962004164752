import React from 'react';
import { NoContent } from 'UI';
import { Styles } from '../../common';
import Bar from './Bar';
function SessionsPerBrowser(props) {
    var data = props.data, metric = props.metric;
    var firstAvg = metric.data.chart[0] && metric.data.chart[0].count;
    var getVersions = function (item) {
        return Object.keys(item)
            .filter(function (i) { return i !== 'browser' && i !== 'count'; })
            .map(function (i) { return ({ key: 'v' + i, value: item[i] }); });
    };
    return (React.createElement(NoContent, { size: "small", show: metric.data.chart.length === 0 },
        React.createElement("div", { className: "w-full", style: { height: '240px' } }, metric.data.chart.map(function (item, i) {
            return React.createElement(Bar, { key: i, className: "mb-4", avg: Math.round(item.count), versions: getVersions(item), width: Math.round((item.count * 100) / firstAvg) - 10, domain: item.browser, colors: Styles.colors });
        }))));
}
export default SessionsPerBrowser;
